import React, { Component } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished' // Adjust styles programmatically
import * as d3 from 'd3' // Graph library
import moment from 'moment' // Times and dates
import _ from 'lodash'

import { layout } from './layoutConfig' // Layout configurations

import ResponsiveWrapper from './ResponsiveWrapper'

const ChartWrapper = styled.div`
  position: relative;
`
const GraphLine = styled.path`
  fill: none;
  stroke: red;
  vector-effect: non-scaling-stroke;
  @supports not (-ms-ime-align: auto) {
    stroke-width: 1;
  }
  @media (min-width: ${layout.breakpoint.xl}) {
    @supports not (-ms-ime-align: auto) {
      stroke-width: 2;
    }
  }
`

const GraphArea = styled.path`
  fill: rgba(150, 150, 150, .55);
  stroke: none;
`

const AxisLine = styled.g`
  fill: none;
  font-size: 1em;
  color: ${props => props.borderColor};
  stroke-width: 1px;
  & > g {
    font-size: 0.5em;
    opacity: .8;
    font-family: ${props => props.theme.fontFamily};
  }
  @media (min-width: ${layout.breakpoint.sm}) {
    stroke-width: 2px;
     & > g {
      font-size: .8em;
      opacity: .8;
      font-family: ${props => props.theme.fontFamily};
    }
  }
`
const YScaleText = styled.text`
  font-size: 0.5em;
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 0.5em;
  }
  @media (min-width: ${layout.breakpoint.xl}) {
    font-size: 1em;
  }
`
const GridLine = styled.g`
  fill: none;
  font-size: 1em;
  color: ${props => rgba(props.borderColor, 0.3)};
  opacity: .5;
`

const TargetLine = styled.div`
  width: 1px;
  position: absolute;
  height: ${props => props.height}px;
  top: ${props => props.marginTop}px;
  left: ${props => props.marginLeft}px;
  pointer-events: none;
  opacity: 0.4;
  background: ${props => props.borderColor};
`
const CurrentLine = styled.line`
  stroke-width: 1;
  vector-effect: non-scaling-stroke;
  transform: translateZ();
  stroke: ${props => props.borderColor};
`
const InputOverlay = styled.div`
  position: absolute;
  top: ${props => props.overlayTopMargin}px;
  display: block;
  height: ${props => props.overlayHeight}px;
  width: ${props => props.overlayWidth}px;
  left: ${props => props.overlayLeftMargin}px;
`

// We use some local variables to speed things up (and fix Microsoft Edge).
// Using setState for setting "global" zoomfactor was too slow for UX.

let currentZoomTransform = false // Instead of using state, we use this variable for faster performance
let currentTargetPosition = ''
let isIEEdge = false
let scaledMSStroke = 2

// Test is client is IE8+ to Edge
if (document.documentMode || /Edge\//.test(navigator.userAgent)){
   isIEEdge = true
}

class MonitoringChart extends Component {

  constructor(props) {
    super(props)

    this.zoom = d3.zoom()
                  .translateExtent([[0, this.props.height], [this.props.parentWidth, this.props.parentHeight]])
                  //.extent([[0, this.props.height], [this.props.parentWidth, this.props.parentHeight]])
                  .scaleExtent([1, 15])
                  .on('zoom', this.zoomed.bind(this))

    this.mouseMove = this.mouseMove.bind(this)
    this.mouseOut = this.mouseOut.bind(this)
    this.updateTargetData = this.updateTargetData.bind(this)

    this.xGrid = React.createRef()
    this.xAxis = React.createRef()
    this.yGrid = React.createRef()
    this.yAxis = React.createRef()
    this.graphLines = React.createRef()
    this.lastCircles = React.createRef()
    this.targetLine = React.createRef()
    this.currentLine = React.createRef()

    this.state = {
      margin: { top: 20, right: 20, bottom: 8, left: 40 },
    }
  }

  zoomed() {
    // This function is called on every zoom event (mouse scroll, pinch zoom etc):
    currentZoomTransform = d3.event.transform

    const {margin} = this.state

    const height = this.props.parentHeight - margin.top - margin.bottom

    const zoomedXScale = currentZoomTransform.rescaleX(this.xScale);
    scaledMSStroke = (2 / currentZoomTransform.k).toFixed(2) // We assume stroke width is 2 and then divide it for MS Edge

    d3.select(this.xAxis.current)
      .call(d3.axisBottom(zoomedXScale).tickFormat(d3.timeFormat("%H:%M")).ticks(7))

    d3.select(this.xGrid.current)
      .call(d3.axisBottom(zoomedXScale).tickSize(-height, 0, 0).tickFormat(""))

    d3.select(this.graphLines.current)
      .attr('transform', 'translate(' + currentZoomTransform.x + ')scale(' + currentZoomTransform.k + ',1)')

    if (isIEEdge) {
      // If user is using IE edge, we compensate the zoom with path size
      // Edge doesn't support non-scaling vectors on transforms :(
      // TODO: make prettier in edge
      d3.select(this.graphLines.current).selectAll("path")
        .attr('stroke-width', scaledMSStroke)
    }

    d3.select(this.lastCircles.current)
      .attr('transform', 'translate(' + currentZoomTransform.x + ')scale(' + currentZoomTransform.k + ',1)')
      .selectAll("ellipse")
        .attr('rx', (4 / currentZoomTransform.k))

    d3.select(this.currentLine.current)
      .attr('x1', zoomedXScale(currentTargetPosition))
      .attr('x2', zoomedXScale(currentTargetPosition))

  }

  componentDidMount() {
    this.updateChart()
  }

  componentDidUpdate() {
    this.updateChart()
  }

  getFormattedDate(timestamp) {
    // Safari has trouble with the timestamp format of our data, so we need do a little magic with it.
    // Btw. using new Date() -method with the data timestamp works fine in Chrome though.
    // So now we format the timestamp with moment, which returns unix time, and pass that unix-time to new Date -method.
    return new Date(moment(timestamp, 'YYYY-MM-DDTHH:mm:ssZ'))
  }

  updateChart(props) {
    const {margin} = this.state
    const {data, viewHistoryStats} = this.props

    const width = this.props.parentWidth - margin.left - margin.right,
          height = this.props.parentHeight - margin.top - margin.bottom

    const xScale = (currentZoomTransform) ? currentZoomTransform.rescaleX(this.xScale) : this.xScale

    // Create an index of unix timestamps we use to find array positions of channels
    this.timeStamps = []
    let i;
    for (i = 0; i <  data.length; i++) {
      this.timeStamps.push( moment(data.timestamp, 'YYYY-MM-DDTHH:mm:ssZ').unix() )
    }

    // Let D3 do some magic with DOM elements. We had to move these d3 functions outside the render function
    // Since React won't allow referencing to ref's inside render (since the nodes don't exist yet)
    // TODO: This should be refactored later on, so that only D3 controls the DOM, or React does. Not a mixture.

    d3.select(this.xAxis.current)
      .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat("%H:%M")).ticks(7))

    d3.select(this.xGrid.current)
      .call(d3.axisBottom(xScale).tickSize(-height, 0, 0).tickFormat(""))

    d3.select(this.yAxis.current)
      .call(d3.axisLeft(this.yScale).tickFormat(this.chartYFormat))

    d3.select(this.yGrid.current)
      .call(d3.axisLeft(this.yScale).tickSize(-width, 0, 0).tickFormat(""))

    if (!viewHistoryStats) {
      d3.select(this.currentLine.current)
      .attr('x1', '0')
      .attr('x2', '0')
      .style('opacity', '0')
    }
  }

  findChannelValuesForTargetTime(target) {
    // Get the current position timestamp, and find the right data:
    const data = this.props.data
    const timeStamps = this.timeStamps
    const currentUserDataSelector = this.props.currentUserDataSelector
    const targetValue = target // Needs to be in unix time
    // Find the closest timestamp value for target in the timestamps array
    const closestValue = timeStamps.reduce(function(prev, curr) {
      return (Math.abs(curr - targetValue) < Math.abs(prev - targetValue) ? curr : prev)
    })
    // Find the array position, we use to get other values
    const targetPosition = timeStamps.indexOf(closestValue)
    let targetDataset = []
    let i
    for (i = 0; i < data.length; i++) {
      let datapoint = data[i][targetPosition]
      if(datapoint) { targetDataset.push(datapoint) }
    }
    // Now sort the data by the active value in use, so the largest number is first in the dataset
    const sortedTargetDataset = _.sortBy(targetDataset, function(item) { return -item[currentUserDataSelector]; });
    this.props.rightNowHandler(sortedTargetDataset)
  }

  updateTargetData(event) {
    // When user taps/clicks a position on the chart timeline:
    const xScale = (currentZoomTransform) ? currentZoomTransform.rescaleX(this.xScale) : this.xScale
    const target = d3.clientPoint(event.target, event)[0]
    const targetTimestamp = xScale.invert(target)
    const formattedTimestamp = moment(targetTimestamp).unix()

    d3.select(this.currentLine.current)
      .attr('x1', xScale(targetTimestamp))
      .attr('x2', xScale(targetTimestamp))
      .style('opacity', '1')
    this.findChannelValuesForTargetTime(formattedTimestamp)
    currentTargetPosition = targetTimestamp // update local variable
  }

  mouseMove(event) {
    // When mouse hover around the chart active area:
    const target = d3.clientPoint(event.target, event)[0]
    d3.select(this.targetLine.current) // Move line with the mouse
      .attr('style', 'transform: translateX(' + target + 'px) translateZ(0)')
  }

  mouseOut(event) {
    // When mouse leaves the chart active area:
    d3.select(this.targetLine.current)
    .attr('style', 'transform: translateX(0) translateZ(0)')
  }

  render() {

    const {data, monitoringThreshold} = this.props
    const {margin} = this.state

    // Only render if data is available
    if (!data) return null

    const width = this.props.parentWidth - margin.left - margin.right,
          height = this.props.parentHeight - margin.top - margin.bottom

    const svgWidth = this.props.parentWidth,
          svgHeight = this.props.parentHeight

    const overlayHeight = height
    const targetLineHeight = height
    const x = d3.scaleTime().rangeRound([0, width]),
          y = d3.scaleLinear().rangeRound([height, 0])


    // Create an array of all datapoints
    // to calculate the max data point for axis y scaling in chart
    const currentTotals = [],
          averageTotalsMax = [],
          averageTotalsMin = []

    _.map(data, function(value) {
        currentTotals.push(value.n) // n is totals from 24 hours
        averageTotalsMax.push( Math.floor(value.n_avg + (value.n_avg * monitoringThreshold)) ) // average from two week + variation
        averageTotalsMin.push( Math.floor(value.n_avg - (value.n_avg * monitoringThreshold)) ) // average from two week - variation
    })


    this.xScale = x.domain([
        d3.min(data, d => this.getFormattedDate(d.timestamp)),
        d3.max(data, d => this.getFormattedDate(d.timestamp))
    ])
    this.yScaleMax = d3.max(currentTotals.concat(averageTotalsMax))
    this.yScale = y.domain([0, this.yScaleMax])

    // Show Y axis as either users or percent depending on selected state.
    this.chartYFormat = d3.format('.2s')

    const createTotalLine = d3.line()
      .curve(d3.curveBasis)
      .x(d => this.xScale(this.getFormattedDate(d.timestamp)))
      .y(d => this.yScale(d.n))

    const createAreaChart = d3.area()
      .curve(d3.curveBasis)
      .x(d => this.xScale(this.getFormattedDate(d.timestamp)))
      .y0(d => this.yScale(Math.floor(d.n_avg + (d.n_avg * monitoringThreshold)) ))
      .y1(d => this.yScale(Math.floor(d.n_avg - (d.n_avg * monitoringThreshold)) ))

    return (
      <ChartWrapper>
        <svg width={svgWidth} height={svgHeight}>
          <defs>
            <clipPath id="clip">
                <rect x="0" y="0" width={width} height={height}/>
            </clipPath>
          </defs>
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            <AxisLine borderColor={this.props.theme.borderColor} ref={this.yAxis}>
              <YScaleText transform="rotate(-90)" y="6" dy="0.71em" fill="#fff">
                {this.props.viewerAmountString}
              </YScaleText>
            </AxisLine>
            <GridLine borderColor={this.props.theme.borderColor} ref={this.yGrid} />
            <GridLine borderColor={this.props.theme.borderColor} transform={`translate(0, ${height})`} ref={this.xGrid} />
            <g clipPath="url(#clip)">
              <g ref={this.graphLines}>

                  <GraphLine
                    id='totals'
                    key='currentTotal'
                    d={ createTotalLine(data) }
                  />

                  <GraphArea
                    id='totals'
                    key='last2wkMax'
                    d={ createAreaChart(data) }
                  />
              </g>
            </g>
            <CurrentLine
              ref={this.currentLine}
              y1="0"
              y2={height}
              x1={width}
              x2={width}
              borderColor={this.props.theme.borderColor}
            />
          </g>
        </svg>

        <svg width={svgWidth} height={60} style={{marginTop: '-8px'}}>
           <AxisLine
            borderColor={this.props.theme.borderColor}
            transform={`translate(${margin.left}, 0)`}
            ref={this.xAxis}
          />
        </svg>
        {/* Note, InputOverlay is missing OnClick event, so add it if you want interactivity with the chart */}
        <InputOverlay
          ref={node => d3.select(node).call(this.zoom)}
          overlayWidth={width}
          overlayHeight={overlayHeight}
          overlayTopMargin={margin.top}
          overlayLeftMargin={margin.left}
          onMouseMove={this.mouseMove}
          onDrag={this.mouseOut}
          onMouseDown={this.mouseOut}
          onTouchEnd={this.updateTargetData}
          onTouchMove={this.mouseMove}
        />
        <TargetLine
          ref={this.targetLine}
          marginLeft={margin.left}
          marginTop={margin.top}
          height={targetLineHeight}
          borderColor={this.props.theme.borderColor}
        />
      </ChartWrapper>
    )
  }
}

export default ResponsiveWrapper(MonitoringChart)


