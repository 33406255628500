// ---------------
// Each box that contains channel data, like viewers, program, percent and channel logo.
// ---------------
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { lighten, darken } from 'polished'
import { channelThemes } from './ChannelThemes'

import { layout } from './layoutConfig' // Layout configurations
import Jdenticon from 'react-jdenticon' // icons for campaigns

const ChannelBoxContainer = styled.div`
  box-sizing: border-box;
  padding: 0.15rem 0.5rem 0.25rem;
  border-radius: 2px;
  text-align: left;
  min-height: 50px;
  position: relative;
  height: auto;
  background-image: linear-gradient(
    ${(props) => lighten(0.08, props.themeBg)},
    ${(props) => darken(0.06, props.themeBg)}
  );
  margin: 0.25rem 2% 0.25rem 2%;
  font-size: 0.7rem;
  @media (min-width: ${layout.breakpoint.lg}) {
    padding: 0.6rem 0.6rem 0.85rem;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    display: flex;
    align-items: ${(props) => (props.tabValue === 0 ? "center" : "")};
    justify-content: left;
    flex-direction: ${(props) => (props.tabValue === 0 ? "row" : "column")};
    font-size: 0.9rem;
    border-radius: 6px;
    border: 0;
    margin: 0 0 0.25rem 0;
    flex-basis: 100%;
    max-width: 100%;
  }
`;
const ChannelBoxData = styled.div`
  box-sizing: border-box;
  padding: 4px;
  @media (min-width: ${layout.breakpoint.lg}) {
    padding: 10px 12px 0 12px;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    padding: 0;
    padding-left: 1em;
  }
`;
const ChannelGeneratedBoxData = styled.div`
  padding: 0;
  padding-left: 1em;
  box-sizing: border-box;
`;
const ChannelBoxStripe = styled.div`
  width: 2px;
  border-radius: 1px;
  background: ${props => props.stripeColor};
  height: 70%;
  display: block;
  position: absolute;
  left: 5px;
  top: 15%;
  @media (min-width: ${layout.breakpoint.lg}) {
    width: 3px;
    left: 8px;
  }
`
const ChannelBoxLogoContainer = styled.div`
  background: #fff;
  width: 32px;
  margin-left: 4px;
  margin-top: 4px;
  border-radius: 2px;
  height: 19px;
  padding: 2px;
  box-sizing: border-box;
  display: block
  float: left;
  align-items: center;
  @media (min-width: ${layout.breakpoint.lg}) {
    width: 50px;
    height: 29px;
    padding: 6px;
    margin-left: 12px;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
     margin-left: 16px;
     margin-top: 0;
     padding: 0;
     position: relative;
     height: 40px;
     width: 72px;
     display: flex;
  }
`
const ChannelBoxGeneratedLogoContainer = styled.div`
  margin-left: 4px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  border-radius: 2px;
  box-sizing: border-box;
  align-items: center;
  height: auto;
  height: auto;
  @media (min-width: ${layout.breakpoint.lg}) {
    margin-left: 12px;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    margin-left: 16px;
    margin-top: 0;
    padding: 0;
  }
`;
const ChannelBoxLogo = styled.img`
  width: 100%
  margin: 0 auto;
  @media (min-width: ${layout.breakpoint.xxl}) {
    width: 98%
  }
`
const ChannelBoxViewers = styled.div`
  display: inline;
  font-size: 1.1em;
  font-weight: 200;
  margin-left: 12px;
  line-height: 1.2em;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: 1.4em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.5em;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    font-size: 1.75em;
    margin: 0;
  }
`;

const ChannelGeneratedBoxViews = styled.div`
  display: inline;
  font-size: 1.1em;
  font-weight: 200;
  margin: 0;
  line-height: 1.2em;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: 1.4em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.5em;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    font-size: 1.75em;
  }
`;
const ChannelBoxPercent = styled.div`
  display: inline;
  opacity: .6;
  font-size: 0.8em;
  padding-left: 0.5em;
  @media (min-width: ${layout.breakpoint.sm}) {
     font-size: 1em;
     padding-left: 0.4em;
  }

`
const ChannelBoxProgram = styled.div`
  font-size: 1em;
  line-height: 1.22em;
  display: inline-block;
  width: 100%;
  margin-top: 6px;
  @media (min-width: ${layout.breakpoint.sm}) {
     font-size: 1em;
     margin-top: 0.2em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.2em;
    margin-top: 0.8em;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    margin-top: 0;
  }
`
const ChannelBoxCampaign = styled.div`
  font-size: 1em;
  line-height: 1.22em;
  display: inline-block;
  width: 100%;
  margin-top: 0;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: 1em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.2em;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
  }
`
const ChannelBoxEventType = styled.div`
  font-size: 1em;
  line-height: 1.72em;
  display: inline-block;
  color: ${(props) => (props.channelColor)};
  width: 100%;
  margin-top: 0;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: 1em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.2em;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
  }
`;
/*const ChannelBoxCampaignID = styled.div`
  font-size: 1em;
  margin-left: 4px;
  line-height: 1.22em;
  display: inline-block;
  width: 100%;
  margin-top: 0;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: 1em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.2em;
    margin-left: 12px;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    margin-left: 16px;
  }
`;*/
const ChannelBoxGeneratedContainer = styled.div`
  opacity: ${(props) => props.opacity};
  display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
}
`;


class ChannelBox extends Component {

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.channelViewers !== prevProps.channelViewers) {
      // do something to animate
    }
  }

  render() {

    const channelId = this.props.channelName
    const tabValue = this.props.tabValue
    const eventStyle = this.props.eventStyle
    const color = this.props.color
    const ignoredTypes = this.props.ignoredTypes
    const eventType = this.props.eventType



    let channelColor
      if (tabValue === 0 && channelThemes.colors[channelId]) {
        channelColor = channelThemes.colors[channelId]
      } else if (color !== undefined) {
        channelColor = channelThemes.colors[Object.keys(channelThemes.colors)[color]];
      } else {
        channelColor = "#FFFFFF";
      }

    let channelLogo
      if (channelThemes.logos[channelId]) {
        channelLogo = channelThemes.logos[channelId]
      } else {
        channelLogo = ''
    }

    return (
      <ChannelBoxContainer
        borderColor={channelColor}
        id={this.props.channelName}
        themeBg={this.props.theme.background}
        tabValue={tabValue}
      >
        <ChannelBoxStripe stripeColor={channelColor} />
        {tabValue === 1 ? (
          <>
            <ChannelBoxGeneratedContainer
              opacity={ignoredTypes.includes(eventType) ? 0.4 : 1}
            >
              <ChannelBoxGeneratedLogoContainer>
                <Jdenticon size={"50"} value={channelId} />
              </ChannelBoxGeneratedLogoContainer>

              <ChannelGeneratedBoxData>
                <ChannelGeneratedBoxViews>
                  {this.props.channelViewers}
                </ChannelGeneratedBoxViews>
                <ChannelBoxPercent>
                  {this.props.channelPercentage}
                </ChannelBoxPercent>
                <ChannelBoxCampaign style={{ opacity: ".6" }}>
                  {eventStyle}
                </ChannelBoxCampaign>
                <ChannelBoxCampaign>{channelId}</ChannelBoxCampaign>
                {this.props.inspecting && (
                  <ChannelBoxEventType channelColor={channelColor}>
                    {eventType}
                  </ChannelBoxEventType>
                )}
              </ChannelGeneratedBoxData>
            </ChannelBoxGeneratedContainer>

            {/*<ChannelBoxCampaignID>{"id: 12345678"}</ChannelBoxCampaignID>*/}
          </>
        ) : (
          <>
            <ChannelBoxLogoContainer>
              <ChannelBoxLogo src={channelLogo} />
            </ChannelBoxLogoContainer>

            {!this.props.epgChannel ? (
              <ChannelBoxData>
                <ChannelBoxViewers>
                  {this.props.channelViewers}
                </ChannelBoxViewers>
                <ChannelBoxPercent>
                  {this.props.channelPercentage}
                </ChannelBoxPercent>
                <ChannelBoxProgram>
                  {this.props.channelProgram}
                </ChannelBoxProgram>
              </ChannelBoxData>
            ) : (
              <ChannelBoxData>
                <ChannelBoxProgram>
                  {this.props.channelProgram}
                </ChannelBoxProgram>
              </ChannelBoxData>
            )}
          </>
        )}
      </ChannelBoxContainer>
    );
  }
}

export default withTheme(ChannelBox);
