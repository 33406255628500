// ---------------
// Show a fixed progress bar at the top of the screen
// ---------------
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { channelThemes } from './ChannelThemes'

import { layout } from './layoutConfig' // Layout configurations
import Jdenticon from 'react-jdenticon' // icons for campaigns

const MyChannelsModalWrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
  background: white;
`

const MyChannelBoxesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 16px;
  margin: 0;
  box-sizing: border-box;
  @media (min-width: ${layout.breakpoint.xxl}) {
    padding: 1.5rem
  }
`
const MyChannelBoxContainer = styled.div`
  width: 33%;
  padding: 0.5em;
  box-sizing: border-box;
`
const MyChannelBox = styled.div`
  width: 100%;
  height: ${(props) => (props.tabValue === 0 ? "60px;" : "100px;")}
  box-sizing: border-box;
  border: 2px solid ${(props) => props.borderColor}
  padding: 8px;
  border-radius: 3px;
  background: url(${(props) => props.backgroundImage}) no-repeat center center;
  background-size: auto 24px;
  box-shadow: ${(props) =>
    props.selectedChannel
      ? "0 2px 28px rgba(0,0,0,.1)"
      : "0 2px 14px rgba(0,0,0,.2)"};
  transform: ${(props) => (props.selectedChannel ? "scale(1)" : "scale(0.9)")};
  filter: ${(props) =>
    props.selectedChannel ? "grayscale(0%)" : "grayscale(100%)"};
  opacity: ${(props) => (props.selectedChannel ? "1" : "0.7")};
  transition: opacity .2s, filter .2s, transform .2s ease-in-out;
  @media (min-width: ${layout.breakpoint.xxl}) {
    height: ${(props) => (props.tabValue === 0 ? "80px;" : "100px;")}
    background-size: auto 32px;
  }
`;
const ChannelBoxCampaign = styled.div`
  font-size: 1em;
  line-height: 1.22em;
  display: inline-block;
  color: #282d33;
  width: 100%;
  margin-top: 0;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: 1em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.2em;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
  }
`;

class MyChannelBoxes extends Component {

  render() {
    const channelLogos = Object.keys(channelThemes.logos)
    const channelsInCurrentData = this.props.networkChannels;

    const events = this.props.events;

    return (
      <MyChannelsModalWrapper>
        {this.props.tabValue === 0 && [
          <MyChannelBoxesWrapper key="forChannels">
            {channelLogos.map((channel) =>
              channelsInCurrentData.includes(channel) ? (
                <MyChannelBoxContainer key={channel}>
                  <MyChannelBox
                    id={channel}
                    selectedChannel={
                      this.props.myChannels.includes(channel) ? true : false
                    }
                    backgroundImage={channelThemes.logos[channel]}
                    borderColor={channelThemes.colors[channel]}
                    onClick={() => {
                      this.props.handleChange(channel);
                    }}
                  />
                </MyChannelBoxContainer>
              ) : (
                ""
              )
            )}
          </MyChannelBoxesWrapper>,
        ]}
        {this.props.tabValue === 1 && [
          <MyChannelBoxesWrapper key="forEvents">
            {events.map((thisEvent) => (
              <MyChannelBoxContainer key={thisEvent}>
                <MyChannelBox
                  id={thisEvent}
                  selectedChannel={
                    this.props.notMyEvents.includes(thisEvent) ? false : true
                  }
                  backgroundImage={channelThemes.logos[thisEvent]}
                  borderColor={
                    this.props.consistantUniqueIDEventData.find(
                      (event) => event.channel === thisEvent
                    )
                      ? channelThemes.colors[
                          Object.keys(channelThemes.colors)[
                            this.props.consistantUniqueIDEventData.find(
                              (event) => event.channel === thisEvent
                            ).color
                          ]
                        ]
                      : "rgb(255, 255, 255)"
                  }
                  onClick={() => {
                    this.props.handleChange(thisEvent);
                  }}
                  tabValue={this.props.tabValue}
                >
                  <Jdenticon size={"50"} value={thisEvent} />
                  <ChannelBoxCampaign>{thisEvent}</ChannelBoxCampaign>
                </MyChannelBox>
              </MyChannelBoxContainer>
            ))}
          </MyChannelBoxesWrapper>,
        ]}
      </MyChannelsModalWrapper>
    );
  }
}

export default withTheme(MyChannelBoxes);
