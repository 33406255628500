import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import withRipple from "./withRipple";
import { layout } from "./layoutConfig"; // Layout configurations

const TabContent = styled.div`
  flex: 1;
  width: 100%;
`;

const TabPanel = ({ children }) => (
  <TabContent role="tabpanel" tabindex="0">
    {children}
  </TabContent>
);

TabPanel.propTypes = {
  children: PropTypes.any.isRequired,
};

class Tabs extends Component {
  static Panel = TabPanel;

  static propTypes = {
    children: PropTypes.any.isRequired,
    tabBreak: PropTypes.string,
  };

  static defaultProps = {
    tabBreak: "768px",
  };

  state = {
    selectedTab: 0,
  };

  selectTab = (tabIndex) => {
    this.props.tabsCallBack(tabIndex);
    this.setState({ selectedTab: tabIndex });
  };

  render() {
    const { children, tabBreak } = this.props;
    const { selectedTab } = this.state;

    return (
      <TabsWrapper>
        <TabList
          breakPoint={tabBreak}
          role="tablist"
          style={{
            display: localStorage.adsk_visible === "true" ? "flex" : "none",
          }}
        >
          {React.Children.map(children, ({ props: { label } }, index) => (
            <TabButton
              role="tab"
              selected={selectedTab === index}
              aria-selected={selectedTab === index ? "true" : "false"}
              onClick={() => this.selectTab(index)}
            >
              {label}
            </TabButton>
          ))}
        </TabList>

        <Content>
          {React.Children.map(children, (comp, index) =>
            selectedTab === index ? comp : undefined
          )}
        </Content>
      </TabsWrapper>
    );
  }
}

const TabsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TabButton = withRipple(styled.button`
  ${(props) => props.theme.fontImport};
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) =>
    props.selected ? props.theme.textColor : props.theme.textColor + "94"};
  flex: 1;
  height: 60px;
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: 1rem;
  }
  @media (min-width: ${layout.breakpoint.xl}) {
    font-size: 1.2rem;
  }
  cursor: default;
  background: transparent;
  outline: none;
  transition: border-color 0.2s ease-in;
  border: none;
  border-bottom: 4px solid
    ${(props) =>
      props.selected ? props.theme.accentColor : props.theme.background};
  &:hover,
  &:focus,
  &:active {
    border-bottom: 4px solid
      ${(props) =>
        props.selected ? props.theme.accentColor : props.theme.background};
  }
`);

const TabList = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media (max-width: ${(props) => props.breakPoint}) {
    height: 50px;
    & > div,
    & > div > button {
      height: 50px;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  padding-top: 16px;
`;

export default Tabs;
