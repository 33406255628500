import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translations: {
        "Suomi katsoo": "Suomi katsoo",
        "Wrong username or password": "Wrong username or password",
        "Enter your username": "Enter your username",
        "Enter your password": "Enter your password",
        Submit: "Submit",
        "Getting data": "Getting data",
        "TV-devices": "TV-devices",
        "Right now": "Right now",
        "Log out": "Log out",
        "Use TV viewers": "Use TV viewers",
        "Edit the channels you want to follow":
          "Edit the channels you want to follow",
        "Unable to get data from server":
          "Connection to the server was disconnected",
        Themes: "Themes",
        "Edit channels": "Edit channels",
        "Edit events": "Edit adverts",
        "Resume data update": "Resume automatic update",
        "Stop Inspecting": "Close event inspection",
        "Average viewer amount": "Average viewer amount",
        "Of All TV-devices": "Of All TV-devices",
        "Show percentages of all devices": "Show percentages of all devices",
        "Show EPG data for other channels":
          "Show program starts for other channels",
        "The service gathers anonymous usage statistics with Google Analytics":
          "The service gathers anonymous usage statistics with Google Analytics",
        "About this service": "About this service",
        InfoModalHeader: "About this service",
        InfoModalP1:
          "Suomi Katsoo –service gives user the popularity of TV-channels in Digita's antenna network right now and how the viewing behaviour has developed within the last 24 hours.",
        InfoModalP2:
          "The service shows in real time how many Hybrid-TV -devices there are connected to a certain tv-channel and what program is currently running.",
        InfoModalP3:
          "From the settings the user can select the channels she wants to follow and the forms of analytics; absolute tv-devices, percentages to all users or Finnpanel multipliers.",
        "avg. viewers": "avg. viewers",
        Events: "Events",
        placeholder: "placeholder",
        Campaigns: "Advertising",
        Channels: "Channels",
        NoData: "No advertisement data to show for the past 30 minutes",
      },
    },
    fi: {
      translations: {
        "Suomi katsoo": "Suomi katsoo",
        "Wrong username or password": "Väärä käyttäjänimi tai salasana",
        "Enter your username": "Syötä käyttäjänimi",
        "Enter your password": "Syötä salasana",
        Submit: "Lähetä",
        "Getting data": "Haetaan tietoja",
        "TV-devices": "TV-laitetta",
        "Right now": "Juuri nyt",
        "Log out": "Kirjaudu ulos",
        "Use TV viewers": "Käytä TV-katsojia",
        "Edit the channels you want to follow":
          "Muokkaa kanavia joita haluat seurata",
        "Unable to get data from server": "Yhteyttä palvelimeen ei saatu.",
        Themes: "Teemat",
        "Edit channels": "Muokkaa kanavia",
        "Edit events": "Muokkaa kampanjoita",
        "Resume data update": "Jatka automaattista päivitystä",
        "Stop Inspecting": "Sulje tapahtuman tarkastelu",
        "Average viewer amount": "Keskikatsojamäärä",
        "Of All TV-devices": "Kaikista TV-laitteista",
        "Show percentages of all devices":
          "Näytä prosentit kaikista tv-laitteista",
        "Show EPG data for other channels":
          "Näytä ohjelmien alut muille kanaville",
        "The service gathers anonymous usage statistics with Google Analytics":
          "Palvelu seuraa käyttöä nimettömästi Google Analytics -evästeillä",
        "About this service": "Tietoa palvelusta",
        InfoModalHeader: "Tietoa palvelusta",
        InfoModalP1:
          "Suomi Katsoo –palvelu kertoo käyttäjälle Digitan antennitelevisiossa olevien tv-kanavien suosion juuri tällä hetkellä sekä miten katselukäyttäytyminen on kehittynyt viimeisen 24 tunnin aikana.",
        InfoModalP2:
          "Palvelu näyttää reaaliajassa kuinka monta Hybridi-TV-laitetta on kytkeytyneenä tietylle tv-kanavalle ja mikä ohjelma kyseisellä tv-kanavalla on juuri nyt menossa.",
        InfoModalP3:
          "Asetuksista käyttäjä voi valita ne kanavat joita haluaa seurata, sekä haluamansa luvut; absoluuttiset tv-laitteet, prosentit suhteessa kaikkiin käyttäjiin tai Finnpanelin kertoimet.",
        "avg. viewers": "katsojaa (keskim.)",
        placeholder: "placeholder",
        Events: "Tapahtumaa",
        Campaigns: "Mainonta",
        Channels: "Kanavat",
        NoData: "Ei dataa viimeisen 30 minuutin ajalta",
      },
    },
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },
  react: {
    wait: true,
  },
});

export default i18n;
