import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { darken } from 'polished'
import settingsIcon from './assets/settings-white.svg'

import { layout } from './layoutConfig' // Layout configurations


const UserSettingsContainer = styled.div`
  width: 100%;
  text-align: right;
  position: relative;
  font-size: .7rem;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: .8rem;
  }
  @media (min-width: ${layout.breakpoint.xl}) {
    font-size: 1rem;
  }
`
const UserAccountName = styled.div`
  font-size: 1rem;
  text-transform: capitalize;
  display: none;
  margin-right: 1em;
  @media (min-width: 800px) {
    display: inline;
  }
`
const IconContainer = styled.div`
  padding: 8px;
  width: 32px;
  box-sizing: border-box;
  height: 32px;
  background: rgba(0,0,0,.1);
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 100%;
  @media (min-width: ${layout.breakpoint.lg}) {
    height: 40px;
    width: 40px;
  }
  @media (min-width: ${layout.breakpoint.xl}) {
    height: 44px;
    width: 44px;
  }
`
const Button = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
`
const Icon = styled.img`
  width: 100%;
`
const DropdownContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  position: absolute;
  top: 120%;
  z-index: 100;
  right: 0;
  width: 95vw;
  max-width: 360px;
  box-sizing: border-box;
  padding: 1em;
  visibility: ${props => props.buttonState ? 'visible' : 'hidden'};
  opacity: ${props => props.buttonState ? '1' : '0'};
  transform: translateY(${props => props.buttonState ? '0px' : '-16px'});
  transition: transform .3s ease, opacity .2s linear;
  box-shadow: 0 0 28px rgba(0,0,0,.1);
  @media (min-width: 800px) {
    padding: 1.5em;
  }
`
const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.8em;
`
const MenuAccountName = styled.div`
  color: #000;
  font-size: 1rem;
  text-transform: capitalize;
`
const Logout = styled.a`
  color: ${props => darken(0.08, props.accentColor)};
  cursor: pointer
  &:hover {
    color: ${props => darken(0.1, props.accentColor)};
  }
`
const MenuSelectionGroup = styled.form`
  display: ${(props) => (props.tabValue === 0 ? "block" : "none")};
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;
const MenuItemRadio = styled.label`
  text-align: right;
  cursor: pointer
  display: flex;
  padding: 0.6rem 0.8em;
  justify-content: space-between;
  align-items: center
  color: #000;
  border-bottom: 1px solid rgba(0,0,0,.1);
  &:last-child {
    border-bottom: 0;
  }
`
const MenuItemRadioInput = styled.input`
`
const MenuItemRadioLabel = styled.div`
  font-size: 0.9em;
`
const MenuItem = styled.div`
  box-shadow: 0 0 24px rgba(0,0,0,.1);
  border-radius: 3px;
  margin: 0.5em 0
`
const EditLink = styled.div`
  color: #000;
  display: block;
  padding: 0.6rem 0.8em;
  cursor: pointer;
`
const MenuItemSelect = styled.select`
  width: 100%;
  padding: 0.5em;
  margin-top: 0.1em;
  font-size: 0.8em;
  box-shadow: 0 0 24px rgba(0,0,0,.1)
`
const MenuItemSelectLabel = styled.div`
  display: block;
  font-size: 0.8em;
  margin-top: 0.5em;
  color: rgba(0,0,0,.6);
  text-transform: uppercase;
`

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {isVisible: false};

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.closeMenuAndEditChannels = this.closeMenuAndEditChannels.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isVisible: !state.isVisible
    }));
  }

  closeMenuAndEditChannels() {
    this.setState(state => ({
      isVisible: !state.isVisible
    }));
    this.props.editMyChannelsHandler()
  }

  render() {
    const useMultipliers = (this.props.currentUserDataSelector === 'nf') ? true : false
    const usePercents = (this.props.currentUserDataSelector === 'np') ? true : false
    const useDevices = (this.props.currentUserDataSelector === 'n') ? true : false
    const showEpgData = this.props.showEpgData
    let finnpanelControl = ''
    let themeOptions = ''
    let devicesControl = ''

    if (this.props.showThemeOptions) {
      themeOptions = (
        <MenuItem style={{ padding: '0.6rem 0.8rem'}}>
            <MenuItemSelectLabel>{this.props.themesString}</MenuItemSelectLabel>
            <MenuItemSelect onChange={e => this.props.themeChangeHandler(e.target.value)}>
              {this.props.themeList.map(themeName => (
                <option key={themeName} value={themeName}>
                  {themeName}
                </option>
              ))}
            </MenuItemSelect>
        </MenuItem>
      )
    }

    if (this.props.showFinnpanelControl) {
      finnpanelControl = (
        <MenuItemRadio>
          <MenuItemRadioInput
            name="viewMode"
            type="radio"
            checked={useMultipliers}
            onChange={this.props.handleUseViewerMultipliers}
          />
          <MenuItemRadioLabel>
            {this.props.useMultipliersString}
          </MenuItemRadioLabel>
        </MenuItemRadio>
      )
    }

    if (this.props.showDevicesControl) {
      devicesControl = (
        <MenuItemRadio>
          <MenuItemRadioInput
            name="viewMode"
            type="radio"
            checked={useDevices}
            onChange={this.props.handleShowTvDevices}
          />
          <MenuItemRadioLabel>
            {this.props.useDevicesString}
          </MenuItemRadioLabel>
        </MenuItemRadio>
      )
    }
    return (
      <UserSettingsContainer>
        <Button onClick={this.handleClick}>
          <UserAccountName>{this.props.accountName}</UserAccountName>
          <IconContainer>
            <Icon src={settingsIcon} alt="settings" />
          </IconContainer>
        </Button>
        <DropdownContainer buttonState={this.state.isVisible}>
          <DropdownHeader>
            <MenuAccountName>{this.props.accountName}</MenuAccountName>
            <Logout
              accentColor={this.props.theme.accentColor}
              onClick={this.props.handleLogout}
            >
              {this.props.logoutString}
            </Logout>
          </DropdownHeader>
          <MenuSelectionGroup tabValue={this.props.tabValue}>
            {devicesControl}

            {finnpanelControl}

            <MenuItemRadio>
              <MenuItemRadioInput
                name="viewMode"
                type="radio"
                checked={usePercents}
                onChange={this.props.handleUsePercentages}
              />
              <MenuItemRadioLabel>
                {this.props.usePercentagesString}
              </MenuItemRadioLabel>
            </MenuItemRadio>
          </MenuSelectionGroup>
          <MenuItem>
            <EditLink onClick={this.closeMenuAndEditChannels}>
              {this.props.tabValue === 0
                ? this.props.editChannelsString
                : this.props.editEventsString}
            </EditLink>
          </MenuItem>
          <MenuItem
            style={{
              display: `${this.props.tabValue === 0 ? "block" : "none"}`,
            }}
          >
            <MenuItemRadio>
              <MenuItemRadioInput
                name="showEpg"
                type="checkbox"
                checked={showEpgData}
                onChange={this.props.handleEpgVisibility}
              />
              <MenuItemRadioLabel>
                {this.props.showEpgString}
              </MenuItemRadioLabel>
            </MenuItemRadio>
          </MenuItem>

          {themeOptions}
        </DropdownContainer>
      </UserSettingsContainer>
    );
  }
}
export default withTheme(UserSettings);
