import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import posed, { PoseGroup } from 'react-pose' // animate header data & button transition
import { darken, rgba } from 'polished'

import UserSettings from './UserSettings'

import { layout } from './layoutConfig' // Layout configurations


const AppHeaderWrapper = styled.div`
  padding: 4px 8px 4px;
  border-bottom: 1px solid ${props => rgba(props.borderColor, 0.1)};
  border-top: 3px solid ${props => darken(0.08, props.indicatorBg)};
  @media (min-width: ${layout.breakpoint.lg}) {
    padding: 0 1.5rem;
    border-top: 5px solid ${props => darken(0.08, props.indicatorBg)};
  }
`
const AppHeaderContainer = styled.div`
  height: 34px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${layout.breakpoint.lg}) {
    height: 68px;
  }
`
const AppLogoContainer = styled.div`
  width: 25%;
  text-align: left;
  margin-top: 4px;
`
const AppLogo = styled.img`
  max-height: 20px;
  @media (min-width: ${layout.breakpoint.lg}) {
    max-height: 34px;
  }
`
const GlobalStatsAnimated= posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: -50, opacity: 0 }
});

const GlobalStats = styled(GlobalStatsAnimated)`
  font-size: 0.65rem;
  text-transform: uppercase;
  width: 50%;
  padding-bottom: 0.25rem;
  text-align: center;
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1.2rem;
    position: relative;
  }
`
const GlobalStatsDevices = styled.div`
  display: block;
  font-weight: 600;
  line-height: 1.2em;
  @media (min-width: 540px) {
    display: inline;
  }
`
const CurrentDateTime = styled.div`
  display: block;
  line-height: 1.2em;
  @media (min-width: 540px) {
    display: inline;
    padding-left: 1rem;
  }
`
const UserSettingsContainer = styled.div`
  width: 25%;
  @media (min-width: ${layout.breakpoint.lg}) {
    position: relative;
  }
`

const ResumeButton = styled.div`
  display: inline-block;
  padding: 0 1em;
  cursor: pointer;
  width: 100%;
  text-transform: none;
  color: #fff;
  background-color: ${props => props.buttonColor};
  transition: background-color .3s;
  height: 32px;
  margin: 0 auto;
  line-height: 30px;
  font-size: 1em;
  text-shadow: 0 1px 5px rgba(0,0,0,.4);
  border-radius: 15px;
  &:hover {
    background-color: ${props => darken(0.08, props.buttonColor)};
  }
  @media (min-width: ${layout.breakpoint.sm}) {
    width: auto;
    padding: 0 1.5em;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1em;
    height: 40px;
    border-radius: 20px;
    line-height: 37px;
  }
`

class AppHeader extends Component {

  render() {
    const viewHistoryState = this.props.viewHistoryStats
    const currentStatsInfo = (
      <GlobalStats key="latestStats">
        <GlobalStatsDevices>
          {this.props.globalDevices.toLocaleString('fi-FI')}
          <span style={{ paddingLeft: '0.2rem' }}>{this.props.tvDevicesString}</span>
        </GlobalStatsDevices>
        <CurrentDateTime>{this.props.latestDate}</CurrentDateTime>
      </GlobalStats>
    )
    const resumeUpdate = (
      <GlobalStats key="updateButton">
        <ResumeButton buttonColor={this.props.theme.accentColor} onClick={this.props.handleResumeAutoupdate}>
          {this.props.resumeUpdateString}
        </ResumeButton>
      </GlobalStats>
    )
    const stopInspecting = (
      <GlobalStats key="stopInspectingButton">
        <ResumeButton
          buttonColor={this.props.theme.accentColor}
          onClick={this.props.inspectEvent}
        >
          {this.props.stopInspectingString}
        </ResumeButton>
      </GlobalStats>
    );
    return (
      <AppHeaderWrapper
        indicatorBg={this.props.theme.background}
        borderColor={this.props.theme.borderColor}
      >
        <AppHeaderContainer>
          <AppLogoContainer>
            <AppLogo src={this.props.theme.logo} alt="logo" />
          </AppLogoContainer>
          <PoseGroup>
            {this.props.inspecting
              ? stopInspecting
              : viewHistoryState
              ? resumeUpdate
              : currentStatsInfo}
          </PoseGroup>
          <UserSettingsContainer>
            <UserSettings
              logoutString={this.props.logoutString}
              accountName={this.props.userName}
              editMyChannelsHandler={this.props.editMyChannelsHandler}
              themeChangeHandler={this.props.themeChangeHandler}
              currentUserDataSelector={this.props.currentUserDataSelector}
              handleLogout={this.props.handleLogout}
              showThemeOptions={this.props.showThemeOptions}
              showFinnpanelControl={this.props.showFinnpanelControl}
              showDevicesControl={this.props.showDevicesControl}
              themeList={this.props.themeList}
              themesString={this.props.themesString}
              editChannelsString={this.props.editChannelsString}
              editEventsString={this.props.editEventsString}
              handleUseViewerMultipliers={this.props.handleUseViewerMultipliers}
              handleUsePercentages={this.props.handleUsePercentages}
              handleShowTvDevices={this.props.handleShowTvDevices}
              useMultipliersString={this.props.useMultipliersString}
              useDevicesString={this.props.useDevicesString}
              usePercentagesString={this.props.usePercentagesString}
              showEpgString={this.props.showEpgString}
              handleEpgVisibility={this.props.handleEpgVisibility}
              showEpgData={this.props.showEpgData}
              tabValue={this.props.tabValue}
            />
          </UserSettingsContainer>
        </AppHeaderContainer>
      </AppHeaderWrapper>
    );
  }
}

export default withTheme(AppHeader);
