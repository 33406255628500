// ---------------
// Show a fixed progress bar at the top of the screen
// ---------------
import React, { Component } from 'react'
import styled, { keyframes, withTheme } from 'styled-components'
import { rgba } from 'polished'

import { layout } from './layoutConfig' // Layout configurations

const fadeAndGrow = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`
const RefreshProgressIndicator = styled.div`
  position: fixed;
  height: 3px;
  display: block;
  z-index: 99;
  transform: translateZ(0);
  width: 100%;
  background: ${props => props.progressBg};
  border-radius: 2px;
  box-shadow: 0 0 32px ${props => rgba(props.progressBg, 0.8)};
  animation: ${fadeAndGrow} ${props => props.transitionTime}s linear infinite;
  @media (min-width: ${layout.breakpoint.lg}) {
    height: 5px;
  }
`


class RefreshProgress extends Component {
  render() {
    const autoUpdateRunning = this.props.autoUpdateRunning
    if (autoUpdateRunning) {
      return (
        <RefreshProgressIndicator progressBg={this.props.theme.accentColor} transitionTime={this.props.transitionTime} />
      )
    } else {
      return (
        <div>
        </div>
      )
    }
  }
}

export default withTheme(RefreshProgress);
