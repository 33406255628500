import digitaLogo from './assets/digita-logo-hybriditv.svg'
import digitaBasicLogo from './assets/digita-logo-white.svg'
import digitaLogoDark from './assets/digita-logo-hybriditv-dark.svg'
import digitaBasicLogoDark from './assets/digita-logo-black.svg'
import nelonenLogo from './assets/nelonen_logo_slogan.svg'
import mtvLogo from './assets/mtv-logo.svg'
import yleLogo from './assets/yle-logo.svg'
import foxLogo from './assets/fox-logo.svg'
import discoveryLogo from './assets/discovery-logo.jpg'
import digitaSpinner from './assets/spinner.svg'

export const themes = {
  digita: {
    background: '#3A414A',
    accentColor: '#C9005C',
    textColor: '#FFFFFF',
    fontImport: "@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,700')",
    fontFamily: "'Titillium Web', sans-serif",
    borderColor: '#FFFFFF',
    letterSpacing: '0.04rem',
    logo: digitaLogo,
    spinner: digitaSpinner,
    footerLogo: digitaBasicLogo,
  },
  digitalight: {
    background: '#FFFFFF',
    accentColor: '#C9005C',
    textColor: '#000000',
    fontImport: "@import url('https://fonts.googleapis.com/css?family=Hind')",
    fontFamily: "'Titillium Web', sans-serif",
    borderColor: '#000000',
    letterSpacing: '0.04rem',
    logo: digitaLogoDark,
    spinner: digitaSpinner,
    footerLogo: digitaBasicLogoDark,
  },
  mtv: {
    background: '#FFFFFF',
    accentColor: '#ff3334',
    textColor: '#4e4e4e',
    fontImport: "@import url('https://fonts.googleapis.com/css?family=Hind')",
    fontFamily: "'Titillium Web', sans-serif",
    borderColor: '#000000',
    letterSpacing: '0.04rem',
    logo: mtvLogo,
    spinner: digitaSpinner,
    footerLogo: digitaBasicLogoDark,
  },
  discovery: {
    background: '#FFFFFF',
    accentColor: '#00accd',
    textColor: '#4e4e4e',
    fontImport: "@import url('https://fonts.googleapis.com/css?family=Lato')",
    fontFamily: "'Lato', sans-serif",
    borderColor: '#000000',
    letterSpacing: 'auto',
    logo: discoveryLogo,
    spinner: digitaSpinner,
    footerLogo: digitaBasicLogoDark,
  },
  nelonen: {
    background: '#000a5a',
    accentColor: '#00ffaf',
    textColor: '#FFFFFF',
    fontImport: "@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,700')",
    fontFamily: 'Hind, sans-serif',
    borderColor: '#FFFFFF',
    letterSpacing: 'auto',
    logo: nelonenLogo,
    spinner: digitaSpinner,
    footerLogo: digitaBasicLogo,
  },
  yle: {
    background: '#262a2e',
    accentColor: '#00bbd4',
    textColor: '#FFFFFF',
    fontImport: "@import url('https://fonts.googleapis.com/css?family=Open+Sans');",
    fontFamily: "'Open Sans', sans-serif",
    borderColor: '#FFFFFF',
    letterSpacing: 'auto',
    logo: yleLogo,
    spinner: digitaSpinner,
    footerLogo: digitaBasicLogo,
  },
  fox: {
    background: '#262a2e',
    accentColor: '#00bbd4',
    textColor: '#FFFFFF',
    fontImport: "@import url('https://fonts.googleapis.com/css?family=Hind')",
    fontFamily: "'Titillium Web', sans-serif",
    borderColor: '#FFFFFF',
    letterSpacing: 'auto',
    logo: foxLogo,
    spinner: digitaSpinner,
    footerLogo: digitaBasicLogo,
  }
};
