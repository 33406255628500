import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import posed, { PoseGroup } from "react-pose";
import MyChannelBoxes from "./MyChannelBoxes";

const AnimatedShade = posed.div({
  enter: { opacity: 1, transition: { duration: 50 } },
  exit: { opacity: 0, transition: { duration: 50 } },
});
const MyChannelsShade = styled(AnimatedShade)`
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 80;
`;
const AnimatedMyChannelsPanel = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      x: { type: "spring", stiffness: 800, damping: 20 },
      default: { duration: 300 },
    },
  },
  exit: {
    x: 50,
    opacity: 0,
    transition: { duration: 300 },
  },
});
const MyChannelsPanel = styled(AnimatedMyChannelsPanel)`
  width: 70%;
  max-width: 480px;
  min-width: 220px;
  position: fixed;
  top: 0;
  z-index: 85;
  right: -20px;
  padding-right: 20px;
`;

class MyChannelsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myChannels: this.props.myChannels,
      myEvents: this.props.myEvents,
      notMyEvents: this.props.notMyEvents,
      events: this.props.events,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props)
      this.setState({
        myEvents: this.props.myEvents,
        notMyEvents: this.props.notMyEvents,
        events: this.props.events,
      });
  }

  handleMyChannelEdit(channel) {
    const myChannels = [...this.state.myChannels];
    const channelName = channel;

    if (myChannels.includes(channelName)) {
      if (myChannels.length !== 1) {
        const index = myChannels.findIndex(
          (channels) => channels === channelName
        );
        if (index > -1) {
          myChannels.splice(index, 1);
        }
        this.setState({ myChannels: myChannels });
      } else {
        return false;
      }
    } else {
      myChannels.push(channelName);
      this.setState({ myChannels: myChannels });
    }
  }

  handleMyEventEdit(event) {
    const notMyEvents = [...this.state.notMyEvents];
    const eventName = event;

    if (notMyEvents.includes(eventName)) {
      const index = notMyEvents.findIndex((events) => events === eventName);
      if (index > -1) {
        notMyEvents.splice(index, 1);
      }
      this.setState({ notMyEvents: notMyEvents });
    } else {
      if (notMyEvents.length < this.state.events.length - 1) {
        notMyEvents.push(eventName);
        this.setState({ notMyEvents: notMyEvents });
      } else {
        return false;
      }
    }
  }

  render() {
    return (
      <PoseGroup>
        {this.props.myChannelsPanelOpen &&
          this.props.tabValue === 0 && [
            <MyChannelsShade
              key="myChannelShade"
              onClick={() => this.props.handleClose(this.state.myChannels)}
            />,
            <MyChannelsPanel key="myChannelPanel">
              <MyChannelBoxes
                modalTitleString={this.props.modalTitleString}
                myChannels={this.state.myChannels}
                channelsInCurrentData={this.props.channelsInCurrentData}
                networkChannels={this.props.networkChannels}
                epgChannels={this.props.epgChannels}
                handleChange={this.handleMyChannelEdit.bind(this)}
                tabValue={this.props.tabValue}
              />
            </MyChannelsPanel>,
          ]}
        {this.props.myChannelsPanelOpen &&
          this.props.tabValue === 1 && [
            <MyChannelsShade
              key="myEventShade"
              onClick={() =>
                this.props.handleEventClose(this.state.notMyEvents)
              }
            />,
            <MyChannelsPanel key="myEventPanel">
              <MyChannelBoxes
                modalTitleString={this.props.modalTitleString}
                myChannels={this.state.myChannels}
                events={this.state.events}
                notMyEvents={this.state.notMyEvents}
                channelsInCurrentData={this.props.channelsInCurrentData}
                networkChannels={this.props.networkChannels}
                epgChannels={this.props.epgChannels}
                handleChange={this.handleMyEventEdit.bind(this)}
                tabValue={this.props.tabValue}
                consistantUniqueIDEventData={
                  this.props.consistantUniqueIDEventData
                }
              />
            </MyChannelsPanel>,
          ]}
      </PoseGroup>
    );
  }
}

export default withTheme(MyChannelsEditor);
