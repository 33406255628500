// ---------------
// The main App that contains all the necessary logic
// ---------------
import React, { Component } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"; // For styling all components, inside components
import { lighten, darken, rgba } from "polished"; // Adjust CSS properties programmatically
import axios from "axios"; // Promised based - Make XMLHttpRequests from the browser
import _ from "lodash"; // Used to handle the data

// Localization
import { translate } from "react-i18next"; // Translate strings
import moment from "moment"; // Times and dates
import "moment/locale/fi";
import "moment/locale/sv";
import i18n from "./Translations"; // Get the translated strings. All the translations uses are here in App.js
import { channelThemes } from "./ChannelThemes";

// Logging
import Log from "./Log"; // This helps us not firing log events in production

// Analytics
import ReactGA from "react-ga"; // React GA component

// Components
import AppHeader from "./AppHeader"; // Header contains the logo, settings, logout and stuff like that
import MonitoringHeader from "./MonitoringHeader"; // Header contains the logo, settings, logout and stuff like that
import RefreshProgress from "./RefreshProgress"; // Show the refreshprogress running at the top of the page
import Chart from "./Chart"; // Chart component for showing the data as a chart
import MonitoringChart from "./MonitoringChart"; // Chart component for showing the monitoring data
import RightNow from "./RightNow"; // Channelboxes that show the numbers and logos
import Spinner from "./Spinner"; // Loader spinner shown when loading data
import Modal from "react-responsive-modal"; // My Channels modal
import MyChannelsEditor from "./MyChannelsEditor";
import Tabs from "./Tabs"; // Tabs that are used to change the graph views

// Themes are the account specific themes for the UI
import { themes } from "./themes"; // Visual theme configurations
import { layout } from "./layoutConfig"; // Layout configurations

// GlobalStyle is applied to all views
const GlobalStyle = createGlobalStyle`
  ${(props) => props.theme.fontImport};
  html {
    -webkit-text-size-adjust: 100%;
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${(props) => darken(0.08, props.theme.background)};
    color: ${(props) => props.theme.textColor};
    font-family: ${(props) => props.theme.fontFamily};
    letter-spacing: ${(props) => props.theme.letterSpacing};
    transition: background .1s linear, color .1s linear;
  }
`;
const AppWrapper = styled.div`
  text-align: center;
  background: ${(props) => props.theme.background};
`;
const AppContentWrapper = styled.div``;
const AppContent = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  @media (min-width: ${layout.breakpoint.sm}) {
    padding: 0 1rem;
  }
  @media (min-width: ${layout.breakpoint.xl}) {
    flex-direction: row;
    min-height: 95vh;
    padding: 0 2rem;
  }
`;
const ChartContainer = styled.div`
  width: 100%;
  height: 200px;
  @media (min-width: ${layout.breakpoint.sm}) {
    height: 400px;
  }
  @media screen and (orientation:landscape) {
    min-height: 180px;
    height: 60vh;
  }
  @media (min-width: ${layout.breakpoint.xl}) {
    max-width: 100%
    min-width: 60%;
    height: auto;
    min-height: 60vh;
    max-height: 78vh;
  }
`;
const LoginBoxContainer = styled.div`
  display: flex;
  min-height: 85vh;
  align-items: center;
`;
const PrivacyInfo = styled.p`
  font-size: 0.8rem;
  padding-top: 1em;
  margin: 0 auto;
  width: 90%
  max-width: 600px;
`;
const LoginBox = styled.form`
  display: block;
  width: 75%;
  min-width: 280px;
  max-width: 500px;
  background-image: linear-gradient(
    ${(props) => lighten(0.08, props.theme.background)},
    ${(props) => darken(0.06, props.theme.background)}
  );
  border-radius: 6px;
  padding: 2rem 2rem 2rem;
  margin: 0 auto;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.3);
  transition: background-image 0.3s linear;
  @media (min-width: ${layout.breakpoint.xxl}) {
    width: 70%;
  }
`;
const LoginLogo = styled.div`
  width: 100%;
  height: 56px;
  margin: 1rem auto 1rem;
  display: flex;
  align-items: center;
  text-align: center;
`;
const LoginLogoImage = styled.img`
  height: 100%;
  margin: 0 auto;
`;
const LoginBoxTitle = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  margin-top: 0;
`;
const TextField = styled.input`
  padding: 1rem;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 6px 40px rgba(0,0,0,.1);
  text-align: center
  border: 0;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1.2rem;
  margin: 0.25rem 0;
  box-sizing: border-box;
`;
const LoginButton = styled.button`
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
  border: 0;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fontFamily};
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 6px;

  background-color: ${(props) => darken(0.06, props.theme.accentColor)};
  transition: background-color 0.3s ease;
  &:hover,
  &:active {
    background-color: ${(props) => darken(0.1, props.theme.accentColor)};
  }
`;
const LoginErrorMessage = styled.p`
  display: block;
`;
const DataErrorMessage = styled.p`
  background: rgba(255, 40, 40, 0.1);
  padding: 0.5rem;
  border-radius: 3px;
  display: block;
`;
const Footer = styled.div`
  background: ${(props) => darken(0.08, props.theme.background)};
  padding-bottom: 1em;
`;
const FooterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => rgba(props.theme.borderColor, 0.1)};
`;
const FooterLogoWrapper = styled.div`
  width: 20%;
  margin: 1em;
  max-width: 100px;
`;
const FooterLogo = styled.img`
  width: 100%;
`;
const LanguageSwitch = styled.a`
  margin: 1rem;
  display: inline-block;
  cursor: pointer;
  font-size: 0.8rem;
  opacity: ${(props) => (props.currentLang ? "1" : ".5")};
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1rem;
  }
`;
const InfoLink = styled.a`
  cursor: pointer;
  margin: 1rem;
  display: inline-block;
  font-size: 0.8rem;
  @media (min-width: ${layout.breakpoint.lg}) {
    font-size: 1rem;
  }
`;
const ModalContent = styled.div`
  color: #101010;
`;
const NoDataToShowMessage = styled.p`
  background: rgba(255, 241, 40, 0.1);
  padding: 0.5rem;
  border-radius: 3px;
  display: block;
  height: fit-content;
  width: -webkit-fill-available;
`;

function resetToPercentages() {
  window.localStorage.setItem("currentUserDataSelector", "np");
  window.location.reload();
}

function mergeTo() {
  // This is a helper function that merges the contents of two or more objects together into the first object.
  // Previously used with jQuery: https://api.jquery.com/jquery.extend/
  // From https://stackoverflow.com/a/11197343
  for (var i = 1; i < arguments.length; i++)
    for (var key in arguments[i])
      if (arguments[i].hasOwnProperty(key)) {
        if (
          typeof arguments[0][key] === "object" &&
          typeof arguments[i][key] === "object"
        )
          mergeTo(arguments[0][key], arguments[i][key]);
        else arguments[0][key] = arguments[i][key];
      }
  return arguments[0];
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: themes.digita,
      isLoggedIn: false,
      dataLoaded: false,
      currentLang: i18n.language, // i18n autodetects browser language
      autoUpdateRunning: false,
      autoUpdateIntervalInMS: 60000,
      showLoginErrorMessage: false,
      showDataErrorMessage: false,
      token: "",
      adsk_visible: false,
      username: this.clientHasLocalStorage()
        ? window.localStorage.getItem("username") || ""
        : "",
      password: "",
      channels: [],
      events: [],
      allEvents: [],
      statistics: { total: 0 },
      showFinnpanelControl: false, // If logged in user is admin, then this is set to true
      showThemeOptions: false, // If logged in user is admin, then this is set to true
      latestUpdate: this.clientHasLocalStorage()
        ? JSON.parse(window.localStorage.getItem("latestUpdate")) || ""
        : "",
      currentUserDataSelector: this.clientHasLocalStorage()
        ? window.localStorage.getItem("currentUserDataSelector") || "np"
        : "np", // can also be 'np' for percents or 'nf' for multipliers – use np by default since it is the only that is shown to everyone
      networkChannels: this.clientHasLocalStorage()
        ? JSON.parse(window.localStorage.getItem("networkChannels")) || []
        : [],
      epgChannels: this.clientHasLocalStorage()
        ? JSON.parse(window.localStorage.getItem("epgChannels")) || []
        : [],
      myEpgChannels: [], // this not really in use yet.
      epgData: this.clientHasLocalStorage()
        ? JSON.parse(window.localStorage.getItem("epgData")) || []
        : [],
      rightNowData: this.clientHasLocalStorage()
        ? JSON.parse(window.localStorage.getItem("rightNowData")) || []
        : [],
      rightNowDataLast: this.clientHasLocalStorage()
        ? window.localStorage.getItem("rightNowDataLast") || ""
        : "",
      showEpgData: false,
      myEventsData: [],
      myEvents: this.clientHasLocalStorage()
        ? JSON.parse(window.localStorage.getItem("myEvents")) || []
        : [],
      notMyEvents: this.clientHasLocalStorage()
        ? JSON.parse(window.localStorage.getItem("notMyEvents")) || []
        : [],
      myChannelsData: [],
      myChannels: this.clientHasLocalStorage()
        ? JSON.parse(window.localStorage.getItem("myChannels")) || []
        : [],
      myChannelsPanelOpen: false,
      eventRightNowData: [],
      /*this.clientHasLocalStorage()
        ? JSON.parse(window.localStorage.getItem("eventRightNowData")) || []
        : []*/ eventRightNowDataLast: this.clientHasLocalStorage()
        ? window.localStorage.getItem("eventRightNowDataLast") || ""
        : "",
      uniqueEventIDs: [],
      consistantUniqueIDEventData: [],
      infoModalOpen: false,
      viewHistoryStats: false,
      hideMonitoringData: false, // Hide monitoring data for other users than monitoring user
      monitoringThreshold: 0.085, // Percentage as decimals (for example 0.1 or 0.755) - this will be converted to decimals when counting the values in graph
      tabValue: 0,

      inspectedEventData: [],
      inspectedEvent: "",
      inspectedEventRightNowData: [],
      ignoredTypes: [],
    };
  }

  componentDidMount() {
    ReactGA.initialize("UA-88071868-2"); // Initialize google analytics
    // If stored token is found from localstorage, log the user in
    const token = this.getToken();
    const username = this.getUsername();
    const notMonitorUser = username === "valvomo" ? false : true;
    if (token && notMonitorUser) {
      Log.info("Token found, logging in", "App (componentDidMount)");
      this.setState({
        isLoggedIn: true,
        hideMonitoringData: true,
      });
      this.fetchEPGData();
      this.fetchNetworkInformationAndStartDataUpdateCycle();
    } else if (token) {
      Log.info(
        "Token found, logging in monitor user",
        "App (componentDidMount)"
      );
      this.setState({
        isLoggedIn: true,
        hideMonitoringData: false,
      });
      this.fetchAndUpdateData();
    }
    this.sendGAPageview();
    this.setTheme();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  clientHasLocalStorage() {
    const mod = "localstoragetest";
    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
  }

  sendGAPageview() {
    // This function is fired on login events (found token in local storage, or logging in),
    // and when login form is displayed.
    const username = this.getUsername();
    if (username) {
      Log.info(
        "Google analytics: " + username + " pageview",
        "App (sendGAPageview)"
      );
      ReactGA.pageview("/" + username);
    } else {
      Log.info(
        "No username found, but user was still marked as logged in",
        "App Component"
      );
      ReactGA.pageview("/undefined-username");
    }
  }

  handleThemeChange = (themeName) => {
    this.setState({ theme: themes[themeName] });
  };

  handleLoginUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  handleLoginPassword = (event) => {
    this.setState({ password: event.target.value });
  };

  handleEpgVisibility = () => {
    if (this.state.showEpgData === true) {
      this.setState({ showEpgData: false });
      ReactGA.event({
        category: "Data view",
        action: "Show EPG-data",
      });
    } else {
      this.setState({ showEpgData: true });
      ReactGA.event({
        category: "Data view",
        action: "Hide EPG-data",
      });
    }
  };

  setToken(token) {
    // or Seth Rogen?
    Log.info("Setting token in state", "App (setToken)");
    this.setState({ token: token });
    if (this.clientHasLocalStorage()) {
      Log.info("Persisting token also in localStorage", "App (setToken)");
      window.localStorage.setItem("token", token);
    } else {
      Log.info(
        "No localstorage available, token only available in app state",
        "App (setToken)"
      );
    }
  }

  setAdskVisible(token) {
    Log.info("Setting adsk_visible in state", "App (setAdskVisible)");
    const adsk_visible = JSON.parse(atob(token.split(".")[1])).adsk_visible;
    this.setState({
      adsk_visible: adsk_visible,
    });
    if (this.clientHasLocalStorage()) {
      Log.info("Persisting token also in localStorage", "App (setToken)");
      window.localStorage.setItem("adsk_visible", adsk_visible);
    } else {
      Log.info(
        "No localstorage available, token only available in app state",
        "App (setAdskVisible)"
      );
    }
  }

  getToken() {
    // Or get Taken? This function has a very specific set of skills.
    // It gets the token or it gets the hose
    const currentToken = this.state.token;
    const localToken = window.localStorage.getItem("token");
    if (currentToken) {
      Log.info("Token found in state", "App (getToken)");
      return currentToken;
    } else if (localToken) {
      Log.info(
        "Token not found in state, but found in local storage. Setting state and getting token.",
        "App (geToken)"
      );
      this.setState({ token: localToken });
      return localToken;
    } else {
      Log.warn("Token not found in state or local storage", "App (getToken");
      return false;
    }
  }

  getUsername() {
    // Get username from state or from localstorage
    const currentUsername = this.state.username;
    const localUsername = window.localStorage.getItem("username");
    if (currentUsername) {
      return currentUsername;
    } else if (localUsername) {
      this.setState({ username: localUsername });
      return localUsername;
    } else {
      Log.warn("Username not found", "App (getUsername");
      return false;
    }
  }

  getMyChannels() {
    const currentMyChannels = this.state.myChannels;
    const localMyChannels = window.localStorage.getItem("myChannels");
    if (currentMyChannels) {
      return currentMyChannels;
    } else if (localMyChannels) {
      this.setState({ myChannels: localMyChannels });
      return localMyChannels;
    } else {
      Log.warn("My channel data not found", "App (getMyChannels");
      return false;
    }
  }

  getMyEvents() {
    const currentMyEvents = this.state.myEvents;
    const localMyEvents = window.localStorage.getItem("myEvents");
    if (currentMyEvents) {
      return currentMyEvents;
    } else if (localMyEvents) {
      this.setState({ myEvents: localMyEvents });
      return localMyEvents;
    } else {
      Log.warn("My event data not found", "App (getMyEvents");
      return false;
    }
  }

  getAdditionalEventData() {
    const uniqueEventIDs = this.state.uniqueEventIDs;
    const localUniqueEventIDs = window.localStorage.getItem("uniqueEventIDs");
    if (uniqueEventIDs) {
      return uniqueEventIDs;
    } else if (localUniqueEventIDs) {
      this.setState({ uniqueEventIDs: localUniqueEventIDs });
      return localUniqueEventIDs;
    } else {
      Log.warn("My event data not found", "App (uniqueEventIDs");
      return false;
    }
  }

  getMyEpgChannels() {
    const currentMyEpgChannels = this.state.myEpgChannels;
    const localMyEpgChannels = window.localStorage.getItem("myEpgChannels");
    if (currentMyEpgChannels) {
      return currentMyEpgChannels;
    } else if (localMyEpgChannels) {
      this.setState({ myEpgChannels: localMyEpgChannels });
      return localMyEpgChannels;
    } else {
      Log.warn("My EPG channel data not found", "App (getMyEpgChannels)");
      return false;
    }
  }

  clearToken() {
    window.localStorage.removeItem("token");
    this.setState({ token: "" });
  }

  setTheme = () => {
    const username = this.getUsername();
    if (username) {
      // Match username to defined theme keys
      for (const key in themes) {
        if (key === username) {
          this.setState({ theme: themes[username] });
          return;
        } else {
          this.setState({ theme: themes.digita });
        }
      }
    } else {
      Log.warn("No username found, so no theme set", "App (setTheme)");
    }
  };

  handleLoginSubmit = (event) => {
    const username = this.state.username,
      password = this.state.password,
      payload = { username: username, password: password };
    this.setTheme();
    Log.info("Sending login data and getting JWT token...");
    axios
      .post(window.API_HOST + "/auth/", payload)
      .then((response) => {
        const data = response.data;
        this.setToken(data.token);
        this.setAdskVisible(data.token);
        this.setState({ isLoggedIn: true });
        // Persist username for matching a correct theme when coming back to the app later
        window.localStorage.setItem("username", username);
        this.setState({ password: "" }); // Clear password from state
        if (username === "valvomo") {
          this.setState({ hideMonitoringData: false });
          this.fetchAndUpdateData();
        } else {
          this.setState({ hideMonitoringData: true });
          this.sendGAPageview();
          this.fetchEPGData();
          this.fetchNetworkInformationAndStartDataUpdateCycle();
        }
      })
      .catch((error) => {
        Log.error("Error happened in login.", "App (handleLoginSubmit)");
        Log.error(error, "App (handleLoginSubmit)");
        this.setState({ showLoginErrorMessage: true });
      });
    event.preventDefault();
    return false;
  };

  fetchEventData() {
    const token = this.getToken();
    if (token) {
      axios({
        method: "get",
        headers: { Authorization: "Bearer " + token },
        url: window.API_HOST + "/adstats/",
        params: {
          //source: "api-1", <- Unnessessary?
          duration: 1440,
        },
      }).then((res) => {
        const data = res.data;
        let i = -1;
        const maxColorCount = Object.keys(channelThemes.colors).length;
        //unfiltered events
        const allEvents = data.events
          ? _.map(data.events, function (value, key) {
              i++;
              return _.flatMap(value, function (item, index) {
                var eventIdentifier = key
                  .replace(/ /g, "")
                  .replace(/&/, "")
                  .replace(/\(|\)/g, "")
                  .replace(/\./g, "")
                  .toLowerCase();

                if (i >= maxColorCount - 1) {
                  i = 0;
                }
                return mergeTo(item, {
                  channel: eventIdentifier,
                  color: i,
                });
              });
            }).filter(function (el) {
              return el[0].channel !== "";
            })
          : this.state.allEvents;

        //filtered events based on dublicate timespams and add the n values together.
        const eventData = data.events
          ? this.eventDataToZeroWhenNoValue(
              _.map(allEvents, function (value, key) {
                i++;
                return _.flatMap(value, function (item, index) {
                  if (i >= maxColorCount - 1) {
                    i = 0;
                  }
                  //This block checks for dublicate timespams and adds the n values together.
                  if (index !== 0 && index !== value.length - 1) {
                    //check if this dataentry does have the same timespamp as the last entry
                    if (
                      moment(item.timestamp).isSame(
                        moment(value[index - 1].timestamp),
                        "minute"
                      )
                    ) {
                      return [];
                    }
                    //we have to check if this data entry has the same timestamp with future entries.
                    if (
                      moment(item.timestamp).isSame(
                        moment(value[index + 1].timestamp),
                        "minute"
                      )
                    ) {
                      let j = 1;
                      let n = item.n;
                      do {
                        n = n + value[index + j].n;
                        j++;
                      } while (
                        index + j < value.length - 1 &&
                        moment(item.timestamp).isSame(
                          moment(value[index + j].timestamp),
                          "minute"
                        )
                      );
                      return [
                        {
                          ...item,
                          n: n,
                        },
                      ];
                    } else {
                      return [{ ...item }];
                    }
                  } else {
                    return [{ ...item }];
                  }
                });
              }).filter(function (el) {
                return el[0].channel !== "";
              }),
              false
            )
          : this.state.events;

        // If not a monitoring user, update state, save information to storage
        if (this.state.hideMonitoringData) {
          this.setState({
            events: eventData, //filtered event data
            allEvents: allEvents, //all event data
          });
        }
      });
    }
  }

  eventDataToZeroWhenNoValue(events, uniqueTypes) {
    //This is where we make sure that the data goes to 0 when there is no data for that minute.
    if (uniqueTypes === false) {
      return _.map(events, function (value) {
        return _.flatMap(value, function (item, index) {
          if (index + 1 < value.length) {
            //create a timestamp 1 minute into the future of the selected value.
            let nextMinute = moment(
              moment(item.timestamp).subtract(1, "minutes")
            );
            //check if this created timestamp does NOT match the next data entry.
            if (
              nextMinute.isSame(value[index + 1].timestamp, "minute") === false
            ) {
              //format the next minute
              nextMinute = nextMinute.format("YYYY-MM-DDTHH:mm:ssZ");

              //Unless the next data entry is 1 minute from this dummy data.
              //create a timestamp 2 minutes into the future of the selected value.
              let afterNextMinute = moment(
                moment(item.timestamp).subtract(2, "minutes")
              );
              if (
                afterNextMinute.isSame(value[index + 1].timestamp, "minute") ===
                false
              ) {
                // we also need to add one more dummy data 1 minute before the next data entry.
                let beforeNextDataEntry = moment(
                  moment(value[index + 1].timestamp).add(1, "minutes")
                );
                //formating the time
                beforeNextDataEntry = beforeNextDataEntry.format(
                  "YYYY-MM-DDTHH:mm:ssZ"
                );
                return [
                  { ...item },
                  {
                    ...item,
                    timestamp: nextMinute,
                    n: 0,
                  },
                  {
                    ...item,
                    timestamp: beforeNextDataEntry,
                    n: 0,
                  },
                ];
              } else {
                //If not, then we just have to add 1 dummy data with n=0 to pull the graph down to 0.
                return [
                  { ...item },
                  {
                    ...item,
                    timestamp: nextMinute,
                    n: 0,
                  },
                ];
              }
            } else {
              return [{ ...item }];
            }
          } else {
            return [{ ...item }];
          }
        });
      });
    } else {
      return _.map(events, function (value) {
        return {
          ...value,
          events: _.flatMap(value.events, function (item, index) {
            if (index + 1 < value.events.length) {
              //create a timestamp 1 minute into the future of the selected value.
              let nextMinute = moment(
                moment(item.timestamp).subtract(1, "minutes")
              );
              //check if this created timestamp does NOT match the next data entry.
              if (
                nextMinute.isSame(
                  value.events[index + 1].timestamp,
                  "minute"
                ) === false
              ) {
                //format the next minute
                nextMinute = nextMinute.format("YYYY-MM-DDTHH:mm:ssZ");

                //Unless the next data entry is 1 minute from this dummy data.
                //create a timestamp 2 minutes into the future of the selected value.
                let afterNextMinute = moment(
                  moment(item.timestamp).subtract(2, "minutes")
                );
                if (
                  afterNextMinute.isSame(
                    value.events[index + 1].timestamp,
                    "minute"
                  ) === false
                ) {
                  // we also need to add one more dummy data 1 minute before the next data entry.
                  let beforeNextDataEntry = moment(
                    moment(value.events[index + 1].timestamp).add(1, "minutes")
                  );
                  //formating the time
                  beforeNextDataEntry = beforeNextDataEntry.format(
                    "YYYY-MM-DDTHH:mm:ssZ"
                  );
                  return [
                    { ...item },
                    {
                      ...item,
                      timestamp: nextMinute,
                      n: 0,
                    },
                    {
                      ...item,
                      timestamp: beforeNextDataEntry,
                      n: 0,
                    },
                  ];
                } else {
                  //If not, then we just have to add 1 dummy data with n=0 to pull the graph down to 0.
                  return [
                    { ...item },
                    {
                      ...item,
                      timestamp: nextMinute,
                      n: 0,
                    },
                  ];
                }
              } else {
                return [{ ...item }];
              }
            } else {
              return [{ ...item }];
            }
          }),
        };
      });
    }
  }

  fetchAndUpdateData() {
    this.fetchEventData();
    // Get JWT token from localStorage which is created on login
    clearTimeout(this.timeout); // Clear timeout if autoupdate is running
    const token = this.getToken();
    if (token) {
      axios({
        method: "get",
        headers: { Authorization: "Bearer " + token },
        url: window.API_HOST + "/stats/",
        params: {
          //source: "api-1", <- Unnessessary?
          duration: 1440, //1440
        },
      })
        .then((res) => {
          const data = res.data;
          const monitoringData = data.totals;

          const monitoringLatestUpdate = Math.max(
            moment(monitoringData[0].timestamp, "YYYY-MM-DDTHH:mm:ssZ").unix() // Get the latest timestamp in unix format
          );

          const channelData = data.channels
            ? _.map(data.channels, function (value, key) {
                return _.map(value, function (item) {
                  var channelIdentifier = key
                    .replace(/ /g, "")
                    .replace(/&/, "")
                    .replace(/\(|\)/g, "")
                    .replace(/\./g, "")
                    .toLowerCase();
                  return mergeTo(item, { channel: channelIdentifier });
                });
              }).filter(function (el) {
                return el[0].channel !== "";
              })
            : this.state.channels;

          const latestUpdate = Math.max(
            ..._.map(data.channels, function (value) {
              return moment(value[0].timestamp, "YYYY-MM-DDTHH:mm:ssZ").unix(); // Get the latest timestamp in unix format
            })
          );

          const channelsInCurrentData = _.map(
            channelData,
            (value) => value[0].channel
          );

          // If not a monitoring user, update state, save information to local storage and handle MyChannel data
          if (this.state.hideMonitoringData) {
            this.setState({
              channels: channelData, // This is the whole channel data, where we have adjusted the channel names
              statistics: data.statistics, // Statistics contain precalculated numbers, like all viewers
              latestUpdate: latestUpdate, // Last timestamp passed to app header
              channelsInCurrentData: channelsInCurrentData, // All channel names found in current data set
              monitoringData: monitoringData, // This is the whole channel data, where we have adjusted the channel names
              monitoringLatestUpdate: monitoringLatestUpdate, // Last timestamp passed to app header
            });
            window.localStorage.setItem(
              "statistics",
              JSON.stringify(data.statistics)
            );
            window.localStorage.setItem(
              "latestUpdate",
              JSON.stringify(latestUpdate)
            );
            window.localStorage.setItem(
              "channelsInCurrentData",
              JSON.stringify(channelsInCurrentData)
            );
            this.updateAndSortMyChannelData();
            this.updateAndSortEventData();
          }

          // If monitoring user, just update state and set data as loaded
          else {
            this.setState({
              monitoringData: monitoringData, // This is the whole channel data, where we have adjusted the channel names
              statistics: data.statistics, // Statistics contain precalculated numbers, like all viewers
              dataLoaded: true,
              monitoringLatestUpdate: monitoringLatestUpdate, // Last timestamp passed to app header
            });
          }

          // And then start the update cycle again
          this.startAutoUpdate();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshToken();
            } else {
              Log.error("Response error");
              this.setState({ showDataErrorMessage: true });
              this.resetStatusAndShowLogin();
            }
          } else if (error.request) {
            Log.error("Request error");
            Log.error(error.request);
            this.setState({ showDataErrorMessage: true });
            this.resetStatusAndShowLogin();
          } else {
            Log.error("Other error");
            Log.error(error.message);
            this.setState({ showDataErrorMessage: true });
            this.resetStatusAndShowLogin();
          }
        });
    } else {
      Log.error("No token found, opening log in view");
      this.resetStatusAndShowLogin();
    }
  }

  fetchEPGData() {
    axios({
      method: "get",
      url: window.API_HOST + "/django_static/epg.json",
    })
      .then((res) => {
        const data = res.data;
        const epgData = _.map(data.programs, function (value, key) {
          return _.map(value, function (item) {
            var channelIdentifier = key
              .replace(/ /g, "")
              .replace(/&/, "")
              .replace(/\(|\)/g, "")
              .replace(/\./g, "")
              .toLowerCase();
            return mergeTo(item, { channel: channelIdentifier });
          });
        });
        const filteredEpgData = epgData.filter(function (el) {
          return el[0] != null; // Clean empty arrays from EPG data
        });
        let epgChannels = [];
        for (let i = 0; i < filteredEpgData.length; i++) {
          epgChannels.push(filteredEpgData[i][0].channel);
        }
        this.setState({
          epgData: filteredEpgData, // all EPG data with adjusted key names
          epgChannels: epgChannels, // all available EPG channel keys
        });
        window.localStorage.setItem("epgData", JSON.stringify(filteredEpgData));
        window.localStorage.setItem("epgChannels", JSON.stringify(epgChannels));
      })
      .catch((error) => {
        if (error.response) {
          Log.error("Response error in fetching EPG data");
          this.setState({ showDataErrorMessage: true });
        } else if (error.request) {
          Log.error("Request error in fetching EPG data");
          Log.error(error.request);
          this.setState({ showDataErrorMessage: true });
        } else {
          Log.error("Other error in fetching EPG data");
          Log.error(error.message);
          this.setState({ showDataErrorMessage: true });
        }
      });
  }

  updateAndSortMyChannelData() {
    // Use data based on our choices in my channels
    const channelData = this.state.channels;
    const myChannels = this.state.myChannels;
    const currentUserDataSelector = this.state.currentUserDataSelector;
    const myChannelsData = _.map(_.range(myChannels.length), function (i) {
      return (
        _.find(channelData, function (item) {
          return item[0].channel === myChannels[i];
        }) || [{ n: 0 }, { n: 0 }]
      );
    });
    const sortedMyChannelsData = _.sortBy(myChannelsData, function (item) {
      return -item[0][currentUserDataSelector];
    });
    let rightNowData = [];
    let i;
    for (i = 0; i < sortedMyChannelsData.length; i++) {
      let datapoint = sortedMyChannelsData[i][0]; // Get the latest datapoints for "right now"
      if (datapoint) {
        rightNowData.push(datapoint);
      }
    }
    this.setState({
      myChannelsData: sortedMyChannelsData,
      dataLoaded: true,
      showDataErrorMessage: false,
      showLoginErrorMessage: false,
      viewHistoryStats: false,
      rightNowData: rightNowData,
      rightNowDataLast: rightNowData[0].timestamp,
    });
    if (rightNowData.length > 0) {
      window.localStorage.setItem("rightNowData", JSON.stringify(rightNowData));
      window.localStorage.setItem(
        "rightNowDataLast",
        JSON.stringify(rightNowData[0].timestamp)
      );
    }
    this.startAutoUpdate();
  }

  updateAndSortEventData() {
    const eventsData = this.state.events;
    const notMyEvents = this.state.notMyEvents;

    let uniqueEventIDs = [];
    let i;
    for (i = 0; i < eventsData.length; i++) {
      let datapoint = eventsData[i][0].channel;
      if (datapoint) {
        uniqueEventIDs.push(datapoint);
      }
    }
    //myEvents Has to sync with uniqueIDs every refresh
    let myEvents = [...uniqueEventIDs];
    //then we remove notMyEvents selections from myEvents
    myEvents.map((unique) => {
      if (notMyEvents.includes(unique)) {
        const index = myEvents.findIndex((thisEvent) => thisEvent === unique);
        if (index > -1) {
          myEvents.splice(index, 1);
        }
        return true;
      } else {
        return false;
      }
    });

    let eventRightNowData = [];
    let consistantUniqueIDEventData = [];
    for (i = 0; i < eventsData.length; i++) {
      let datapoint = eventsData[i][0];
      if (datapoint) {
        consistantUniqueIDEventData.push(datapoint);
        if (!notMyEvents.includes(datapoint.channel))
          eventRightNowData.push(datapoint);
      }
    }
    if (eventRightNowData.length > 0 || uniqueEventIDs.length > 0) {
      this.setState({
        dataLoaded: true,
        showDataErrorMessage: false,
        showLoginErrorMessage: false,
        viewHistoryStats: false,
        eventRightNowData: eventRightNowData,
        eventRightNowDataLast: eventRightNowData[0].timestamp,
        uniqueEventIDs: uniqueEventIDs,
        consistantUniqueIDEventData: consistantUniqueIDEventData,
        myEvents: myEvents,
      });
      window.localStorage.setItem(
        "uniqueEventIDs",
        JSON.stringify(uniqueEventIDs)
      );
      window.localStorage.setItem(
        "consistantUniqueIDEventData",
        JSON.stringify(consistantUniqueIDEventData)
      );
      window.localStorage.setItem(
        "eventRightNowData",
        JSON.stringify(eventRightNowData)
      );
      window.localStorage.setItem(
        "eventRightNowDataLast",
        JSON.stringify(eventRightNowData[0].timestamp)
      );
    }
  }

  fetchNetworkInformationAndStartDataUpdateCycle() {
    // Get JWT token from localStorage which is created on login
    const token = this.getToken();
    if (token) {
      axios({
        method: "get",
        headers: { Authorization: "Bearer " + token },
        url: window.API_HOST + "/channels/",
      })
        .then((res) => {
          const myChannels = this.getMyChannels();
          const myEpgChannels = this.getMyEpgChannels();
          this.getMyEvents();
          this.getAdditionalEventData();
          // We get the channel names from data that belong to this username's network
          const data = res.data;
          let networkChannels = [];
          for (let i = 0; i < data.channels.length; i++) {
            networkChannels.push(
              // fix channel names to match the ones in viewer data
              data.channels[i]
                .replace(/ /g, "")
                .replace(/&/, "")
                .replace(/\(|\)/g, "")
                .replace(/\./g, "")
                .toLowerCase()
            );
          }
          this.setState({ networkChannels: networkChannels });
          window.localStorage.setItem(
            "networkChannels",
            JSON.stringify(networkChannels)
          );
          // If user has no my channels set, let's use network channels by default
          if (myChannels.length === 0) {
            this.setState({ myChannels: networkChannels });
          }
          // If user has no epg channels selected, use network channels (unless admin)
          if (myEpgChannels.length === 0) {
            if (data.is_admin === true) {
              const firstFourChannels = networkChannels.slice(0, 4);
              this.setState({ myEpgChannels: firstFourChannels });
            } else {
              this.setState({ myEpgChannels: networkChannels });
            }
          }
          if (data.is_admin === true) {
            this.setState({ showThemeOptions: true, showFinnpanelControl: true, showDevicesControl: true});
          }
          if (data.show_finnpanel === true) {
            this.setState({ showFinnpanelControl: true });
          } else if (this.state.currentUserDataSelector === 'nf') {
            resetToPercentages();
          }
          if (data.show_devices === true) {
            this.setState({ showDevicesControl: true });
          } else if (this.state.currentUserDataSelector === 'n') {
            resetToPercentages();
          }
          if (myChannels <= 0) {
            // Open my channels selection?
          }
          // Get channel data, now that we know our network
          this.fetchAndUpdateData();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshToken();
            } else {
              Log.error("Response error", "App (fetchNetworkInformation..)");
              this.setState({ showDataErrorMessage: true });
              this.resetStatusAndShowLogin();
            }
          } else if (error.request) {
            Log.error(
              "Request error in fetching network info",
              "App (fetchNetworkInformation..)"
            );
            this.setState({ showDataErrorMessage: true });
            Log.error(error.request);
            this.resetStatusAndShowLogin();
          } else {
            Log.error(
              "Other error in fetching network info",
              "App (fetchNetworkInformation..)"
            );
            this.setState({ showDataErrorMessage: true });
            Log.error(error.message);
            this.resetStatusAndShowLogin();
          }
        });
    } else {
      Log.error("No token found, opening log in view");
      this.resetStatusAndShowLogin();
    }
  }

  changeLanguage = (language) => {
    i18n.changeLanguage(language);
    this.setState({ currentLang: i18n.language });
    ReactGA.event({
      category: "User",
      action: "Changed language",
    });
  };

  openInfoModal = () => {
    this.setState({ infoModalOpen: true });
    ReactGA.event({
      category: "User",
      action: "Viewed info modal",
    });
  };

  closeInfoModal = () => {
    this.setState({ infoModalOpen: false });
  };

  rightNowHandler = (dataset, upToDateTabValue) => {
    clearTimeout(this.timeout);
    if (dataset.length > 0) {
      if (upToDateTabValue === 0) {
        this.setState({
          autoUpdateRunning: false,
          rightNowData: dataset,
          rightNowDataLast: dataset[0].timestamp,
          viewHistoryStats: true,
        });
      }
      if (upToDateTabValue === 1) {
        const notMyEvents = this.state.notMyEvents;
        let eventRightNowData = [];
        let i = 0;
        for (i = 0; i < dataset.length; i++) {
          let datapoint = dataset[i];
          if (datapoint && !notMyEvents.includes(datapoint.channel)) {
            eventRightNowData.push(datapoint);
          }
        }

        this.setState({
          autoUpdateRunning: false,
          eventRightNowData: eventRightNowData,
          eventRightNowDataLast: dataset[0].timestamp,
          viewHistoryStats: true,
        });
      }
      ReactGA.event({
        category: "Data view",
        action: "Viewing history",
      });
    } else {
      return false;
    }
  };

  handleResumeAutoupdate = () => {
    //channel side
    const myChannelsData = this.state.myChannelsData;
    let rightNowData = [];
    let i;
    for (i = 0; i < myChannelsData.length; i++) {
      let datapoint = myChannelsData[i][0];
      if (datapoint) {
        rightNowData.push(datapoint);
      }
    }
    this.setState({
      rightNowData: rightNowData,
      eventRightNowDataLast: rightNowData[0].timestamp,
      viewHistoryStats: false,
    });
    //ad side
    const events = this.state.events;
    let eventRightNowData = [];
    for (i = 0; i < events.length; i++) {
      let datapoint = events[i][0];
      if (datapoint && !this.state.notMyEvents.includes(datapoint.channel)) {
        eventRightNowData.push(datapoint);
      }
    }
    this.setState({
      eventRightNowData: eventRightNowData,
      eventRightNowDataLast: eventRightNowData[0].timestamp,
      viewHistoryStats: false,
    });

    this.fetchAndUpdateData();
  };

  handleLogout = (event) => {
    Log.info("Logging out user - clearing local storage and resetting state");
    event.preventDefault();
    window.localStorage.clear();
    this.resetStatusAndShowLogin();
    ReactGA.event({
      category: "User",
      action: "Logged out",
    });
  };

  handleUseViewerMultipliers = (event) => {
    // 'nf' = amount of devices multiplied by viewer factors specified in the backend
    event.target.check = true;
    this.setState({ currentUserDataSelector: "nf" }, () =>
      this.updateAndSortMyChannelData()
    );
    window.localStorage.setItem("currentUserDataSelector", "nf");
    ReactGA.event({
      category: "Data view",
      action: "With multipliers",
    });
  };
  handleUsePercentages = (event) => {
    // 'np' = relative percentage of viewers at that point to every device
    event.target.check = true;
    this.setState({ currentUserDataSelector: "np" }, () =>
      this.updateAndSortMyChannelData()
    );
    window.localStorage.setItem("currentUserDataSelector", "np");
    ReactGA.event({
      category: "Data view",
      action: "Percentages",
    });
  };
  handleShowTvDevices = (event) => {
    // 'n' = amount of tv-devices in the data - default
    event.target.check = true;
    this.setState({ currentUserDataSelector: "n" }, () =>
      this.updateAndSortMyChannelData()
    );
    window.localStorage.setItem("currentUserDataSelector", "n");
    ReactGA.event({
      category: "Data view",
      action: "TV-devices",
    });
  };

  handleMonitoringThresholdChange = (event) => {
    const thresholdValue = event.target.value;
    this.setState({ monitoringThreshold: thresholdValue });
  };

  resetStatusAndShowLogin() {
    Log.info("Resetting status and opening login");
    clearTimeout(this.timeout);
    this.setState({
      isLoggedIn: false,
      networkChannels: [],
      myChannels: [],
      myEvents: [],
      notMyEvents: [],
      uniqueEventIDs: [],
      consistantUniqueIDEventData: [],
      dataLoaded: false,
      autoUpdateRunning: false,
      hideMonitoringData: true,
      tabValue: 0,
      showFinnpanelControl: false,
      showDevicesControl: false,
      showThemeOptions: false,
      channelsInCurrentData: [],
      inspectedEventData: [],
      inspectedEvent: "",
      inspectedEventRightNowData: [],
      allEvents: [],
      ignoredTypes: [],
    });
  }

  startAutoUpdate() {
    clearTimeout(this.timeout);
    // If we're viewing history data, don't keep updating
    if (!this.state.viewHistoryStats) {
      this.setState({ autoUpdateRunning: true });
      this.timeout = setTimeout(() => {
        this.setState({ autoUpdateRunning: false });
        this.fetchAndUpdateData();
      }, this.state.autoUpdateIntervalInMS);
    }
  }

  refreshToken() {
    // Get JWT token from localStorage which is created on login
    const token = this.getToken();

    if (token) {
      Log.warn(
        "Error fetching data. Token was probably expired, trying to refresh token: " +
          token
      );
      axios({
        method: "post",
        url: window.API_HOST + "/auth/refresh/",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          var data = response.data;
          Log.info("Updating token: " + data.token);
          this.setToken(data.token);
          setTimeout(() => {
            this.fetchAndUpdateData();
          }, 500);
        })
        .catch((error) => {
          this.clearToken();
          Log.error("Refreshing errored: " + error);
          this.resetStatusAndShowLogin();
        });
    } else {
      Log.error(
        "Tried to refresh but no token found in local storage. Going to login view."
      );
      this.resetStatusAndShowLogin();
    }
  }

  openMyChannelsPanel = () => {
    this.setState({ myChannelsPanelOpen: true });
    ReactGA.event({
      category: "User",
      action: "Edited visible channels",
    });
  };

  closeMyChannelsPanel = (myChannels) => {
    // Callback within callback
    // Not that pretty, but avoids updating everything at once (causing fps problems)
    this.setState({ myChannelsPanelOpen: false }, () =>
      setTimeout(() => {
        this.setState({ myChannels: myChannels }, () =>
          setTimeout(() => {
            this.updateAndSortMyChannelData();
          }, 500)
        );
      }, 500)
    );
    window.localStorage.setItem("myChannels", JSON.stringify(myChannels));
  };
  closeMyChannelsPanelFromEvents = (notMyEvents) => {
    // Callback within callback
    // Not that pretty, but avoids updating everything at once (causing fps problems)
    this.setState({ myChannelsPanelOpen: false }, () =>
      setTimeout(() => {
        this.setState({ notMyEvents: notMyEvents }, () =>
          setTimeout(() => {
            this.updateAndSortEventData();
          }, 500)
        );
      }, 500)
    );
    window.localStorage.setItem("notMyEvents", JSON.stringify(notMyEvents));
  };

  handleTabCallback = (tab) => {
    this.setState({ tabValue: tab });
    if (this.state.inspectedEventData.length !== 0) {
      this.inspectEvent();
    }
    //this.fetchAndUpdateData(tab);
  };

  getInspectedEventData = (eventId) => {
    //This function gets the unique event types and sorts all events into them.
    var eventIndex = this.state.allEvents.findIndex(
      (element) => element[0].channel === eventId
    );
    var inspectedData = [...this.state.allEvents[eventIndex]];
    const maxColorCount = Object.keys(channelThemes.colors).length;

    //Here we get the unique events, and then make them into objects witn an event array.
    let uniqueTypes = [...new Set(inspectedData.map((item) => item.eventType))];
    uniqueTypes = [
      ...new Set(
        uniqueTypes.map((item, index) => ({
          id: item,
          events: [],
          color: index < maxColorCount ? index : 0,
        }))
      ),
    ];

    //Here we sort all of this channels events into the right event types in "uniqueTypes" object array.
    uniqueTypes = this.eventDataToZeroWhenNoValue(
      uniqueTypes.flatMap((type) => {
          var matchingEvents = inspectedData.filter(
            (event) => event.eventType === type.id
          );
          return {
            ...type,
            events: _.concat(type.events, matchingEvents),
          };
      }),
      true
    );

    //Here we also set the custom data for RightNow
    var inspectedEventRightNowData = [
      ...new Set(
        uniqueTypes.map((item) => ({
          channel: eventId,
          color: item.color,
          eventType: item.id,
          n: item.events.reduce((acc, item) => item.timestamp === this.state.eventRightNowDataLast ? item.n  : acc, 0),
          id: item.events[0].id,
        }))
      ),
    ];

    //we need to process uniqueTypes once more in order to remove any that are chosen to be ignored.
    uniqueTypes = uniqueTypes.flatMap((type) => {
      if (this.state.ignoredTypes.includes(type.id)) {
        return [];
      } else {
        return [type];
      }
    });

    this.setState({
      inspectedEventData: uniqueTypes,
      inspectedEventRightNowData: inspectedEventRightNowData,
    });
  };

  inspectEvent = (eventId) => {
    //this is a callback event from Right now Boxes as they are clicked.
    if (this.state.inspectedEvent === "") {
      this.setState({ inspectedEvent: eventId });
      this.getInspectedEventData(eventId);
    } else {
      this.setState({
        inspectedEvent: "",
        inspectedEventData: [],
        inspectedEventRightNowData: [],
        ignoredTypes: []
      });
    }
  };

  hideEventType = (eventType) => {
    //we hide a clicked eventType from the graph.
    var ignoredTypes = this.state.ignoredTypes;
    if (ignoredTypes.includes(eventType)){
      ignoredTypes.splice(ignoredTypes.indexOf(eventType), 1);
    }else if(ignoredTypes.length < this.state.inspectedEventRightNowData.length -1){
      ignoredTypes.push(eventType)
    }
    this.setState({ ignoredTypes: ignoredTypes });
    this.getInspectedEventData(this.state.inspectedEvent);
  };

  render() {
    const { t, i18n } = this.props;
    const isLoggedIn = this.state.isLoggedIn;
    const dataLoaded = this.state.dataLoaded;
    const showLoginError = this.state.showLoginErrorMessage;
    const showDataError = this.state.showDataErrorMessage;
    const hideMonitoringData = this.state.hideMonitoringData;

    let content;
    let loginError = "";
    let dataError = "";

    moment.locale(i18n.language); // Set moment localisation to current language

    if (showLoginError) {
      loginError = (
        <LoginErrorMessage>{t("Wrong username or password")}</LoginErrorMessage>
      );
    }
    if (showDataError) {
      dataError = (
        <DataErrorMessage>
          {t("Unable to get data from server.")}
        </DataErrorMessage>
      );
    }

    // Show this if the user has not logged in
    if (!isLoggedIn) {
      content = (
        <div>
          <LoginBoxContainer>
            <LoginBox onSubmit={this.handleLoginSubmit}>
              <LoginLogo>
                <LoginLogoImage src={this.state.theme.logo} />
              </LoginLogo>
              <LoginBoxTitle>{t("Suomi katsoo")}</LoginBoxTitle>
              <TextField
                hintText={t("Enter your username")}
                value={this.state.username}
                onChange={this.handleLoginUsername}
                onBlur={this.setTheme}
              />
              <TextField
                type="password"
                value={this.state.password}
                hintText={t("Enter your password")}
                onChange={this.handleLoginPassword}
              />
              <LoginButton type="Submit">{t("Submit")}</LoginButton>
              {loginError}
              {dataError}
            </LoginBox>
          </LoginBoxContainer>
          <Footer>
            <PrivacyInfo>
              {t(
                "The service gathers anonymous usage statistics with Google Analytics"
              )}
            </PrivacyInfo>
            <LanguageSwitch
              currentLang={this.state.currentLang === "fi-FI" ? true : false}
              onClick={() => this.changeLanguage("fi-FI")}
            >
              FI
            </LanguageSwitch>
            <LanguageSwitch
              currentLang={this.state.currentLang === "en-GB" ? true : false}
              onClick={() => this.changeLanguage("en-GB")}
            >
              EN
            </LanguageSwitch>
            <FooterFooter>
              <FooterLogoWrapper>
                <FooterLogo src={themes.digita.footerLogo} alt="logo" />
              </FooterLogoWrapper>
              <InfoLink onClick={this.openInfoModal}>
                {t("About this service")}
              </InfoLink>
            </FooterFooter>
          </Footer>
        </div>
      );
    }

    // Loggin in triggers data loading, when data has finished, only then render the content
    else if (isLoggedIn && dataLoaded && hideMonitoringData) {
      content = (
        <div>
          <AppContentWrapper viewHistoryStats={this.state.viewHistoryStats}>
            <RefreshProgress
              autoUpdateRunning={this.state.autoUpdateRunning}
              transitionTime={this.state.autoUpdateIntervalInMS / 1000}
            />
            <AppHeader
              globalDevices={this.state.statistics.total}
              onChangeTheme={this.handleThemeChange}
              userName={this.state.username}
              viewHistoryStats={this.state.viewHistoryStats}
              handleResumeAutoupdate={this.handleResumeAutoupdate}
              currentLanguage={i18n.language}
              handleLogout={this.handleLogout}
              handleUseViewerMultipliers={this.handleUseViewerMultipliers}
              handleUsePercentages={this.handleUsePercentages}
              handleShowTvDevices={this.handleShowTvDevices}
              editMyChannelsHandler={this.openMyChannelsPanel}
              themeChangeHandler={this.handleThemeChange}
              handleEpgVisibility={this.handleEpgVisibility}
              showEpgData={this.state.showEpgData}
              currentUserDataSelector={this.state.currentUserDataSelector}
              showThemeOptions={this.state.showThemeOptions}
              showFinnpanelControl={this.state.showFinnpanelControl}
              showDevicesControl={this.state.showDevicesControl}
              themeList={Object.keys(themes)}
              themesString={t("Themes")}
              useMultipliersString={t("Use TV viewers")}
              usePercentagesString={t("Show percentages of all devices")}
              showEpgString={t("Show EPG data for other channels")}
              useDevicesString={t("TV-devices")}
              logoutString={t("Log out")}
              tvDevicesString={t("TV-devices")}
              resumeUpdateString={t("Resume data update")}
              stopInspectingString={t("Stop Inspecting")}
              editChannelsString={t("Edit channels")}
              editEventsString={t("Edit events")}
              latestDate={moment.unix(this.state.latestUpdate).format("L LT")}
              tabValue={this.state.tabValue}
              inspecting={
                this.state.inspectedEventData.length === 0 ? false : true
              }
              inspectEvent={this.inspectEvent}
            />
            <Tabs
              tabsCallBack={this.handleTabCallback}
              permissions={this.adsk_visible}
            >
              <Tabs.Panel label={t("Channels")}>
                <AppContent>
                  <ChartContainer>
                    <Chart
                      theme={this.state.theme}
                      data={this.state.myChannelsData}
                      epgData={this.state.epgData}
                      showEpgData={this.state.showEpgData}
                      myEpgChannels={this.state.myEpgChannels}
                      epgChannels={this.state.epgChannels}
                      rightNowHandler={this.rightNowHandler}
                      myChannels={this.state.myChannels}
                      viewHistoryStats={this.state.viewHistoryStats}
                      myNetwork={this.state.networkChannels}
                      tabValue={this.state.tabValue}
                      currentUserDataSelector={
                        this.state.currentUserDataSelector
                      }
                      viewerAmountString={
                        this.state.currentUserDataSelector === "nf"
                          ? t("Average viewer amount")
                          : this.state.currentUserDataSelector === "np"
                          ? t("Of All TV-devices")
                          : t("TV-devices")
                      }
                      inspecting={false}
                    />
                  </ChartContainer>
                  <RightNow
                    data={this.state.rightNowData}
                    lastDataPoint={this.state.rightNowDataLast}
                    viewHistoryStats={this.state.viewHistoryStats}
                    myChannels={this.state.myChannels}
                    networkChannels={this.state.networkChannels}
                    currentLanguage={i18n.language}
                    globalDevices={
                      this.tabValue === 0 ? this.state.statistics.total : 0
                    }
                    deviceCount={
                      this.tabValue === 0 ? this.state.statistics.own : 0
                    }
                    channelsInCurrentData={this.state.channelsInCurrentData}
                    deviceCountPercent={
                      this.tabValue === 0
                        ? this.state.statistics.percentage_str
                        : "0.00 %"
                    }
                    currentUserDataSelector={this.state.currentUserDataSelector}
                    rightNowString={t("Right now")}
                    tvDevicesString={
                      this.state.currentUserDataSelector === "nf"
                        ? t("avg. viewers")
                        : t("TV-devices")
                    }
                    tabValue={this.state.tabValue}
                    ignoredTypes={this.state.ignoredTypes}
                    showTvDevices={this.state.showDevicesControl}
                  />
                </AppContent>
              </Tabs.Panel>
              <Tabs.Panel label={t("Campaigns")}>
                <AppContent>
                  {this.state.events.length === 0 && (
                    <NoDataToShowMessage> {t("NoData")}</NoDataToShowMessage>
                  )}
                  {this.state.events.length !== 0 && (
                    <>
                      <ChartContainer>
                        <Chart
                          theme={this.state.theme}
                          data={
                            this.state.inspectedEventData.length === 0
                              ? this.state.events
                              : this.state.inspectedEventData
                          }
                          epgData={this.state.epgData}
                          showEpgData={false}
                          //myEpgChannels={this.state.myEpgChannels}
                          rightNowHandler={this.rightNowHandler}
                          myChannels={this.state.myEvents}
                          viewHistoryStats={this.state.viewHistoryStats}
                          myNetwork={this.state.myEvents}
                          currentUserDataSelector={"n"}
                          viewerAmountString={t("Events")}
                          tabValue={this.state.tabValue}
                          inspecting={
                            this.state.inspectedEventData.length === 0
                              ? false
                              : true
                          }
                        />
                      </ChartContainer>
                      <RightNow
                        eventData={
                          this.state.inspectedEvent === ""
                            ? this.state.eventRightNowData
                            : this.state.inspectedEventRightNowData
                        }
                        showTvDevices={this.state.showDevicesControl}
                        lastDataPoint={this.state.eventRightNowDataLast}
                        viewHistoryStats={this.state.viewHistoryStats}
                        myChannels={this.state.myChannels}
                        networkChannels={this.state.networkChannels}
                        currentLanguage={i18n.language}
                        channelsInCurrentData={this.state.channelsInCurrentData}
                        currentUserDataSelector={
                          this.state.currentUserDataSelector
                        }
                        rightNowString={t("Right now")}
                        tvDevicesString={
                          this.state.currentUserDataSelector === "nf"
                            ? t("avg. viewers")
                            : t("TV-devices")
                        }
                        tabValue={this.state.tabValue}
                        inspectEvent={this.inspectEvent}
                        hideEventType={this.hideEventType}
                        inspecting={
                          this.state.inspectedEvent === "" ? false : true
                        }
                        ignoredTypes={this.state.ignoredTypes}
                      />
                    </>
                  )}
                </AppContent>
              </Tabs.Panel>
            </Tabs>
          </AppContentWrapper>
          <Footer>
            <LanguageSwitch
              currentLang={this.state.currentLang === "fi-FI" ? true : false}
              onClick={() => this.changeLanguage("fi-FI")}
            >
              FI
            </LanguageSwitch>
            <LanguageSwitch
              currentLang={this.state.currentLang === "en-GB" ? true : false}
              onClick={() => this.changeLanguage("en-GB")}
            >
              EN
            </LanguageSwitch>
            <FooterFooter>
              <FooterLogoWrapper>
                <FooterLogo src={themes.digita.footerLogo} alt="logo" />
              </FooterLogoWrapper>
              <InfoLink onClick={this.openInfoModal}>
                {t("About this service")}
              </InfoLink>
            </FooterFooter>
          </Footer>
          <MyChannelsEditor
            tabValue={this.state.tabValue}
            myChannelsPanelOpen={this.state.myChannelsPanelOpen}
            handleClose={this.closeMyChannelsPanel}
            handleEventClose={this.closeMyChannelsPanelFromEvents}
            modalTitleString={t("Edit the channels you want to follow")}
            myChannels={this.state.myChannels}
            events={this.state.uniqueEventIDs}
            myEvents={this.state.myEvents}
            notMyEvents={this.state.notMyEvents}
            consistantUniqueIDEventData={this.state.consistantUniqueIDEventData}
            channelsInCurrentData={this.state.channelsInCurrentData}
            networkChannels={this.state.networkChannels}
            epgChannels={this.state.epgChannels}
          />
        </div>
      );
    } else if (isLoggedIn && dataLoaded) {
      content = (
        <div>
          <AppContentWrapper viewHistoryStats={this.state.viewHistoryStats}>
            <RefreshProgress
              autoUpdateRunning={this.state.autoUpdateRunning}
              transitionTime={this.state.autoUpdateIntervalInMS / 1000}
            />
            <MonitoringHeader
              globalDevices={this.state.statistics.total}
              userName={this.state.username}
              viewHistoryStats={this.state.viewHistoryStats}
              handleResumeAutoupdate={this.handleResumeAutoupdate}
              currentLanguage={i18n.language}
              handleLogout={this.handleLogout}
              handleMonitoringThresholdChange={
                this.handleMonitoringThresholdChange
              }
              monitoringThreshold={this.state.monitoringThreshold}
              currentUserDataSelector={this.state.currentUserDataSelector}
              logoutString={t("Log out")}
              tvDevicesString={t("TV-devices")}
              resumeUpdateString={t("Resume data update")}
              monitoringLatestDate={moment
                .unix(this.state.monitoringLatestUpdate)
                .format("L LT")}
            />
            <AppContent>
              <ChartContainer>
                <MonitoringChart
                  theme={this.state.theme}
                  data={this.state.monitoringData}
                  rightNowHandler={this.rightNowHandler}
                  viewHistoryStats={this.state.viewHistoryStats}
                  currentUserDataSelector={this.state.currentUserDataSelector}
                  monitoringThreshold={this.state.monitoringThreshold}
                />
              </ChartContainer>
            </AppContent>
          </AppContentWrapper>
          <Footer>
            <LanguageSwitch
              currentLang={this.state.currentLang === "fi-FI" ? true : false}
              onClick={() => this.changeLanguage("fi-FI")}
            >
              FI
            </LanguageSwitch>
            <LanguageSwitch
              currentLang={this.state.currentLang === "en-GB" ? true : false}
              onClick={() => this.changeLanguage("en-GB")}
            >
              EN
            </LanguageSwitch>
            <FooterFooter>
              <FooterLogoWrapper>
                <FooterLogo src={themes.digita.footerLogo} alt="logo" />
              </FooterLogoWrapper>
              <InfoLink onClick={this.openInfoModal}>
                {t("About this service")}
              </InfoLink>
            </FooterFooter>
          </Footer>
        </div>
      );
    }

    // This case the user has logged in, but data has not yet loaded - so we show loading.
    else {
      content = <Spinner loaderStatusMessage={t("Getting data")} />;
    }

    // Render content inside the theme providers and style wrappers
    // ThemeProvider provides the theme settings for all included components (that are exported 'withTheme')
    // GlobalStyle sets basic stuff for body and html
    // ModalContent is always present, but shown only when called
    return (
      <ThemeProvider theme={this.state.theme}>
        <AppWrapper>
          <GlobalStyle />
          {content}
          <Modal
            styles={{ modal: { borderRadius: "10px", padding: "1em 1.5em" } }}
            open={this.state.infoModalOpen}
            onClose={this.closeInfoModal}
            center
          >
            <ModalContent>
              <h1>{t("InfoModalHeader")}</h1>
              <p>{t("InfoModalP1")}</p>
              <p>{t("InfoModalP2")}</p>
              <p>{t("InfoModalP3")}</p>
            </ModalContent>
          </Modal>
        </AppWrapper>
      </ThemeProvider>
    );
  }
}

export default translate("translations")(App);
