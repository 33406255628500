// ---------------
// Renders ChannelBoxes and arranges them nicely when data updates
// ---------------
import React, { Component } from 'react'
import styled from 'styled-components'
import posed, { PoseGroup } from 'react-pose';
import _ from 'lodash'
import moment from 'moment'

import ChannelBox from './ChannelBox'

import { layout } from './layoutConfig' // Layout configurations

const RightNowContainer = styled.div`
  font-weight: 200;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: block;
  margin-top: 1rem;
  padding: 0 8px 1.5rem;

  @media (min-width: ${layout.breakpoint.sm}) {
    padding: 0 0 1.5rem 0;
  }

  @media (min-width: ${layout.breakpoint.xl}) {
    margin-top: 0;
    padding: 0 0 1.5rem;
    width: 30%;
    max-width: 500px;
    min-width: 400px;
    border-left: 1px solid rgba(255,255,255,.1);
    padding-left: 1rem;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    padding-left: 2rem;
  }
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #FF0000;
  }
`
const RightNowHeaderContainer = styled.div`
  text-align: left;
  margin: 1.5rem 0 1rem;

`
const RightNowHeader = styled.h2`
  width: auto;
  display: inline;
  text-transform: uppercase;
  font-size: 1rem;
  text-align: left;
  font-weight: 200;
  margin: 0;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: 1.2rem;
  }
  @media (min-width: ${layout.breakpoint.xxl}) {
    font-size: 1.4rem;
  }
`
const RightNowSubHeader = styled.div`
  width: auto;
  display: inline;
  font-size: 0.7rem;
  text-align: left;
  padding-left: 0.5rem;
  opacity: 0.8;
  font-weight: 200;
  margin: 0;
  @media (min-width: ${layout.breakpoint.sm}) {
    font-size: 1rem;
  }
`
const RightNowFooter = styled.div`
  margin-top: 1rem;
  @media (min-width: ${layout.breakpoint.xxl}) {
    font-size: 1.4rem;
  }
`
const RightNowDeviceCount = styled.div`
  width: auto;
  justify-content: flex-end;
  font-size: 0.8rem;
  display: inline;
  text-align: right;
  @media (min-width: ${layout.breakpoint.xxl}) {
    font-size: 1.1rem;
  }
`
const ChannelBoxes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const AnimatedChannelBox = posed.div({
  flip: {
    transition: {
      default: {
        type: 'spring',
        stiffness: 180,
        damping: 21,
        delay: 800,
      }
    }
  },
  enter: {
    opacity: 1,
    scale: 1,
    originX: '50%',
    originY: '50%',
    delay: 800
  },
  exit: {
    opacity: 0,
    scale: 0.9,
    originX: '50%',
    originY: '50%',
    delay: 400,
  }
});

const ChannelBoxContainer = styled(AnimatedChannelBox)`
  width: 100%;
  flex-basis: 50%;
  max-width: 50%;
  position: relative;
  @media (min-width: ${layout.breakpoint.xl}) {
    flex-basis: 100%;
    max-width: 100%;
  }
  &:before {
    content:'';
    display: block;
    transform: scale(0);
    text-align: center;
    width:16px;
    font-weight: bold;
    font-size: .6rem;
    height:16px;
    border-radius: 100%;
    color: #000;
    line-height: 15px;
    position: absolute;
    top: -4px;
    left: -2px;
    z-index: 40;
    box-shadow: 0px 0px 15px rgba(0,0,0,.3);
    background: white;
    transition: transform .3s ease-in-out;
  }
  @media (min-width: ${layout.breakpoint.lg}) {
    &:before {
      width:22px;
      height:22px;
      font-size: .8rem;
      line-height: 21px;
      top: -4px;
      left: -8px;
    }
  }
  &:nth-child(1):before {
    content:'1';
    transform: scale(1);
  }
  &:nth-child(2):before {
    content:'2';
    transform: scale(1);
  }
  &:nth-child(3):before {
    content:'3';
    transform: scale(1);
  }
`

export default class RightNow extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: true };
  }

  sumViewers(obj, selector) {
    let sum = 0;
    for (let el in obj) {
      if (obj.hasOwnProperty(el)) {
        let value = parseFloat(obj[el][selector]);
        if (!isNaN(value)) {
          sum += value;
        }
      }
    }
    return sum;
  }
  inspectEvent = (channelId) => {
    this.props.inspectEvent(channelId)
  };

  render() {
    const data = this.props.data;
    const eventData = this.props.eventData;

    const myChannels = this.props.myChannels;
    const networkChannels = this.props.networkChannels;
    const dataNumberSelector = this.props.currentUserDataSelector;
    const viewHistoryStats = this.props.viewHistoryStats;
    const showTvDevices = this.props.showTvDevices;
    const dataPointTimeAgo = moment(
      this.props.lastDataPoint,
      "YYYY-MM-DDTHH:mm:ssZ"
    ).fromNow();
    const dataPointTime = moment(
      this.props.lastDataPoint,
      "YYYY-MM-DDTHH:mm:ssZ"
    ).format("L LT");

    // Count the viewers to display below boxes
    const summedViewers = this.sumViewers(data, "nf");
    const summedDevices = this.sumViewers(data, "n");
    const summedPercentages =
      (this.sumViewers(data, "np") * 100).toFixed(2) + " %";

    // Show devices if percentages (nf) is selected
    const currentSumAmount =
      dataNumberSelector === "nf" ? summedViewers : summedDevices;

    // Which Tab are we in
    const tabValue = this.props.tabValue;
    //Inspect event callback
    const inspectEvent = this.props.inspectEvent;
    //hide event type callback
    const hideEventType = this.props.hideEventType;
    const ignoredTypes = this.props.ignoredTypes;
    //inspect state
    const inspecting = this.props.inspecting;

    return (
      <RightNowContainer buttonState={this.state.isVisible}>
        {this.props.lastDataPoint !== "" && (
          <RightNowHeaderContainer>
            <RightNowHeader>
              {!viewHistoryStats ? this.props.rightNowString : dataPointTimeAgo}
            </RightNowHeader>
            <RightNowSubHeader>
              {this.props.lastDataPoint !== "" ? dataPointTime : ""}
            </RightNowSubHeader>
          </RightNowHeaderContainer>
        )}
        <ChannelBoxes>
          {tabValue === 0 && (
            <PoseGroup>
              {_.map(data, function (value) {
                const channelId = value.channel;
                const percentageString = (value.np * 100).toFixed(2) + " %";
                const percentage =
                  dataNumberSelector !== "np" ? percentageString : "";
                if (networkChannels.includes(channelId)) {
                  return (
                    <ChannelBoxContainer key={channelId}>
                      <ChannelBox
                        epgChannel={false}
                        channelName={channelId}
                        key={channelId}
                        channelViewers={
                          dataNumberSelector === "np"
                            ? percentageString
                            : value[dataNumberSelector].toLocaleString("fi-FI")
                        }
                        channelPercentage={
                          dataNumberSelector === "nf" ? null : percentage
                        }
                        channelProgram={value.program}
                        tabValue={tabValue}
                      />
                    </ChannelBoxContainer>
                  );
                } else if (myChannels.includes(channelId)) {
                  return (
                    <ChannelBoxContainer key={channelId}>
                      <ChannelBox
                        epgChannel={true}
                        channelName={channelId}
                        key={channelId}
                        channelProgram={value.program}
                      />
                    </ChannelBoxContainer>
                  );
                }
              })}
            </PoseGroup>
          )}
          {tabValue === 1 && (
            <PoseGroup>
              {_.map(eventData, function (value) {
                const eventId = value.channel;
                const eventType = value.eventType;
                const eventStyle = value.id;
                const color = value.color;
                return (
                  <ChannelBoxContainer
                    key={inspecting ? eventType : eventId}
                    onClick={() => {
                      inspecting
                        ? hideEventType(eventType)
                        : inspectEvent(eventId);
                    }}
                  >
                    <ChannelBox
                      epgChannel={false}
                      channelName={eventId}
                      key={inspecting ? eventType : eventId}
                      channelViewers={value["n"].toLocaleString("fi-FI")}
                      eventStyle={eventStyle}
                      tabValue={tabValue}
                      color={color}
                      eventType={eventType}
                      inspecting={inspecting}
                      ignoredTypes={ignoredTypes}
                    />
                  </ChannelBoxContainer>
                );
              })}
            </PoseGroup>
          )}
        </ChannelBoxes>
        <RightNowFooter>
          {tabValue === 0 && showTvDevices && (
            <RightNowDeviceCount>
              <strong style={{ marginRight: "0.2rem" }}>
                {currentSumAmount.toLocaleString("fi-FI")}
              </strong>
              {this.props.tvDevicesString} ({summedPercentages})
            </RightNowDeviceCount>
          )}
        </RightNowFooter>
      </RightNowContainer>
    );
  }
}
