import React, { Component } from 'react'
import styled, { keyframes, withTheme } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`
const LoaderContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 140px;
  height: 140px;
  text-align: center;
  margin-top: -70px;
  margin-left: -70px;
`
const Loader = styled.div`
  margin: 0 auto;
  width: 48px;
  height: 48px;
  animation: ${rotate} 1s infinite;
`
const LoaderImage = styled.img`
  width: 100%;
`
const LoaderStatus = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
`


class Spinner extends Component {
  render() {
    return (
      <LoaderContainer>
        <Loader>
          <LoaderImage src={this.props.theme.spinner} alt="spinner" />
        </Loader>
        <LoaderStatus>{this.props.loaderStatusMessage}</LoaderStatus>
      </LoaderContainer>
    )
  }
}

export default withTheme(Spinner);


